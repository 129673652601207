import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "typeField", "labelField", "placeholderField", "choicesFieldset"];

  connect() {
    this.disableUnusableFields();
  }

  disableUnusableFields() {
    this.labelFieldTarget.disabled = false;
    this.placeholderFieldTarget.disabled = false;

    if (this.hasChoicesFieldsetTarget) this.choicesFieldsetTarget.classList.add('d-none');

    if (this.selectedFieldType === 'ignored') {
      this.labelFieldTarget.disabled = true;
      this.placeholderFieldTarget.disabled = true;
    } else if (this.selectedFieldType === 'checkbox') {
      this.placeholderFieldTarget.disabled = true;
    } else if (this.selectedFieldType === 'radios') {
      this.labelFieldTarget.disabled = true;
      this.choicesFieldsetTarget.classList.remove('d-none');
    }
  }

  get selectedFieldType() {
    return this.typeFieldTarget.value;
  }
}
