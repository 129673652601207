import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import Rectifier from "../javascript/utils/rectifier";

export default class extends Controller {
  static targets = ["canvas", "spinner", "errorText", "formTrigger", "fileField", "cornerXField", "cornerYField"];

  static values = {
    imageUrl: String,
    statusInfo: Object,
    failedStateIndicatable: Boolean,
  };

  rectifier;

  connect() {
    this.rectifier = new Rectifier({imageUrl: this.imageUrlValue, canvas: this.canvasTarget})

    this.rectifier.onLoad(() => this.hideSpinner());
    this.rectifier.onUpdate((status) => this.statusInfoValue = status);
    this.rectifier.beforeRectify(() => this.showSpinner());
    this.rectifier.load(this.#initialCornerPositions);
  }

  populateForm(event) {
    if (!this.rectifier) return;

    this.failedStateIndicatableValue = true;
    this.rectifier.failedStateIndicatable = true;

    this.formTriggerTarget.text = "Loading";
    this.formTriggerTarget.classList.add("disabled");

    const didRectify = this.rectifier.rectify();

    if (!didRectify) {
      this.formTriggerTarget.text = "Next";
      this.#indicateFailedState();
      return;
    }

    didRectify.then((rectificationData) => {
      const {file: rectifiedImage, corners: cornerPositions} = rectificationData;

      // Set file field
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(rectifiedImage);
      this.fileFieldTarget.files = dataTransfer.files;

      // Set 'x' values for canvas corner fields
      this.cornerXFieldTargets.forEach((field) => {
        const handleId = Number.parseInt(field.dataset.position, 10);
        field.value = cornerPositions[handleId].x;
      });

      // Set 'y' values for canvas corner fields
      this.cornerYFieldTargets.forEach((field) => {
        const handleId = Number.parseInt(field.dataset.position, 10);
        field.value = cornerPositions[handleId].y;
      });

      const referenceImageForm = this.fileFieldTarget.closest("form");
      Rails.fire(referenceImageForm, "submit");
    });
  }

  statusInfoValueChanged() {
    if (this.statusInfoValue.valid === false) {
      this.#indicateFailedState();
    } else {
      this.#indicateSuccessState();
    }
  }

  hideSpinner() {
    this.canvasTarget.style.zIndex = "unset";
    this.spinnerTarget.classList.add("d-none");
  }

  showSpinner() {
    this.canvasTarget.style.zIndex = -1;
    this.spinnerTarget.classList.remove("d-none");
  }

  #indicateFailedState() {
    if (!this.failedStateIndicatableValue) return;

    this.errorTextTarget.classList.remove("d-none");
    this.formTriggerTarget.classList.add("disabled");
  }

  #indicateSuccessState() {
    this.errorTextTarget.classList.add("d-none");
    this.formTriggerTarget.classList.remove("disabled");
  }

  get #initialCornerPositions() {
    const cornerPositions = [{}, {}, {}, {}]

    // Get previous 'x' values from corner fields
    this.cornerXFieldTargets.forEach((field) => {
      const handleId = Number.parseInt(field.dataset.position, 10);
      const value = Number.parseInt(field.value, 10)

      if (!isNaN(value)) cornerPositions[handleId].x = value;
    });

    // Get previous 'y' values from corner fields
    this.cornerYFieldTargets.forEach((field) => {
      const handleId = Number.parseInt(field.dataset.position, 10);
      const value = Number.parseInt(field.value, 10)

      if (!isNaN(value)) cornerPositions[handleId].y = value;
    });

    // Note: Values scaled to original image, not the rectification UI
    return cornerPositions;
  }
}
