import { Controller } from "stimulus";
import { initializeEditor } from "../javascript/stitches/composite_image";

export default class extends Controller {

	static values = {
		imageUrl: String,
		previousTransformations: Object,
	};

	trigger() {
		initializeEditor(this.imageUrlValue, this.previousTransformationsValue);
	}
}
