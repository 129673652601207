import { Controller } from "@hotwired/stimulus";
import { Collapse } from "bootstrap";

export default class extends Controller {
  static targets = ["primaryLink", "nestedLink", "rootPageLink"];

  connect() {
    this.updateLinks();
  }

  updateLinks() {
    const currentUrl = window.location.pathname;
    let activeNestedLink;

    // If a nested link's URL matches current URL, make it `.active`
    this.nestedLinkTargets.forEach((nestedLink) => {
      const linkedUrl = nestedLink.getAttribute("href");

      if (linkedUrl === currentUrl) {
        nestedLink.classList.add("active");
        activeNestedLink = nestedLink;
      } else {
        nestedLink.classList.remove("active");
      }
    });

    // Repeat for primary links, but also check if it toggles
    // an active link and expand or collapse it accordingly.
    this.primaryLinkTargets.forEach((primaryLink) => {
      const linkedUrl = primaryLink.getAttribute("href");

      if (linkedUrl === currentUrl || this.#togglesNestedLink(primaryLink, activeNestedLink)) {
        primaryLink.classList.add("active");
        this.#expandTargetCollapsible(primaryLink);
      } else {
        primaryLink.classList.remove("active");
        this.#collapseTargetCollapsible(primaryLink);
      }
    });

    // If no primary link is active, assume we're on the root page
    if (!this.primaryLinkTargets.some((primaryLink) => primaryLink.classList.contains("active"))) {
      this.rootPageLinkTarget.classList.add("active");
    }
  }

  #togglesNestedLink(primaryLink, otherLink) {
    if (!otherLink) return false;

    const targetCollapsibleSelector = primaryLink.dataset.bsTarget;
    if (!targetCollapsibleSelector) return false;

    const targetCollapsible = this.#targetedCollapsible(primaryLink)
    if (!targetCollapsible) return false;

    return targetCollapsible.contains(otherLink);
  }

  #expandTargetCollapsible = (primaryLink) => {
    const targetCollapsible = this.#targetedCollapsible(primaryLink);
    if (!targetCollapsible) return;

    primaryLink.classList.add("show");
    Collapse.getOrCreateInstance(targetCollapsible, {toggle: false}).show();
  }

  #collapseTargetCollapsible = (primaryLink) => {
    const targetCollapsible = this.#targetedCollapsible(primaryLink);
    if (!targetCollapsible) return;

    Collapse.getOrCreateInstance(targetCollapsible, {toggle: false}).hide();
  }

  #targetedCollapsible(primaryLink) {
    const targetCollapsibleSelector = primaryLink.dataset.bsTarget;
    if (!targetCollapsibleSelector) return;

    return document.querySelector(targetCollapsibleSelector);
  }
}
