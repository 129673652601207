import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static values = {
    autoShow: Boolean,
  }

  bsModal;

  connect() {
    this.bsModal = Modal.getOrCreateInstance(this.element);

    if (this.autoShowValue) this.show();
  }

  show() {
    this.bsModal.show();
  }

  hide() {
    this.bsModal.hide();
  }

  clear() {
    this.element.innerHTML = '';
  }
}
