import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["editor", "foregroundColorPicker", "backgroundColorPicker"];

  openForegroundColorPicker() {
    this.foregroundColorPickerTarget.click();
  }

  openBackgroundColorPicker() {
    this.backgroundColorPickerTarget.click();
  }

  updateForegroundColor() {
    const selectedColor = this.foregroundColorPickerTarget.value;
    this.editorTarget.editor.activateAttribute("foregroundColor", selectedColor);
  }

  updateBackgroundColor() {
    const selectedColor = this.backgroundColorPickerTarget.value;
    this.editorTarget.editor.activateAttribute("backgroundColor", selectedColor);
  }
}
