import * as d3 from "d3";
import cv from "@techstark/opencv-js"
import {distanceBetween, pointsToMat, rectifiedDimensions} from "./coordinates";
import {fetchImage, scaleImage} from "./images";
import { clamp } from "./math";

// TODO: Consider combining the Interface class with Rectifier

class Interface {
  onLoadCallback = () => {};
  failedStateOnlineColor = "#d9534f";
  handleRadius = 17;

  backgroundImage; // Re-scaled version of original image
  scale;           // Canvas pixels per original image pixel
  handles;         // Array of x/y objects representing corner points
  d3Transform;     // Current zoom/pan transformation object if present
  rectifier;

  constructor(canvas) {
    this.canvas = canvas;
    this.context = this.canvas.getContext("2d");
  }

  load(originalImage, preferredHandlePositions = [], rectifier) {
    this.rectifier = rectifier;

    // Set internal canvas dimensions
    const internalWidth = 700;
    this.scale = internalWidth / originalImage.width;
    this.canvas.width = internalWidth;
    this.canvas.height = originalImage.height * this.scale;

    // Set initial handle positions
    this.handles = [
      { x: this.handleRadius * 2, y: this.handleRadius * 2 },
      { x: this.canvas.width - this.handleRadius * 2, y: this.handleRadius * 2 },
      { x: this.canvas.width - this.handleRadius * 2, y: this.canvas.height - this.handleRadius * 2 },
      { x: this.handleRadius * 2, y: this.canvas.height - this.handleRadius * 2 },
    ];

    // Prefer input positions if provided
    preferredHandlePositions.forEach((preferredPosition, i) => {
      if (preferredPosition === undefined) return;
      if (preferredPosition.x !== undefined) this.handles[i].x = preferredPosition.x * this.scale;
      if (preferredPosition.y !== undefined) this.handles[i].y = preferredPosition.y * this.scale;
    });

    // Scale image down to internal canvas size
    scaleImage(originalImage, internalWidth)
      .then((rescaledImage) => {
        this.backgroundImage = rescaledImage;
        this.render();
        this.onLoadCallback();

        d3.select(this.canvas)
          .call(this.dragCallbacks)
          .call(this.zoomCallbacks);
      })
      .catch((_failureEvent) => {
        alert("Failed to load image!");
      });
  }

  render(options = {}) {
    if (options.transform) this.d3Transform = options.transform;

    const outlineColor = options.outline || "rgb(13, 153, 255)";

    // Clear canvas
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // Draw fog layer
    this.context.globalCompositeOperation = "source-over";
    this.context.fillStyle = "rgba(255, 255, 255, 0.75)";
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);

    // Cut a hole in the fog using the cropped area
    this.context.globalCompositeOperation = "destination-out";
    this.context.beginPath();

    for (const handlePosition of this.handles) {
      const {x, y} = this.#transformPosition(handlePosition);
      this.context.lineTo(x, y);
    }
    this.context.closePath();
    this.context.fill();

    // Draw image behind the fog
    this.context.globalCompositeOperation = "destination-over";

    const {x: sx, y: sy} = this.#transformPosition({ x: 0, y: 0 });
    const {x: sxe, y: sye} = this.#transformPosition({ x: this.canvas.width, y: this.canvas.height });
    const sw = sxe - sx;
    const sh = sye - sy;

    this.context.drawImage(this.backgroundImage,
      sx, sy,
      sw, sh,
    );

    // Draw outline
    this.context.globalCompositeOperation = "source-over";
    this.context.lineWidth = 4;
    this.context.beginPath();
    for (const handlePosition of this.handles) {
      const {x, y} = this.#transformPosition(handlePosition);
      this.context.lineTo(x, y);
    }
    this.context.closePath();
    this.context.strokeStyle = outlineColor;
    this.context.stroke();

    // Draw handles
    this.handles.forEach((handlePosition, id) => {
      const {x, y} = this.#transformPosition(handlePosition);

      // Outer
      this.context.beginPath();
      this.context.arc(x, y, this.handleRadius, 0, Math.PI * 2);
      this.context.strokeStyle = outlineColor;
      this.context.stroke();

      if (options.selectedHandleId === id) return;

      // Inner
      this.context.beginPath();
      this.context.arc(x, y, this.handleRadius * 0.5, 0, Math.PI * 2);
      this.context.fillStyle = outlineColor;
      this.context.fill();
    });
  }

  renderForFailedState() {
    if (this.rectifier.failedStateIndicatable) {
      this.render({outline: this.failedStateOnlineColor});
    } else {
      this.render();
    }
  }

  clear() {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  localizeCursorLocation(event, constrain = false) {
    const canvasBoundary = this.canvas.getBoundingClientRect();
    const {left: canvasStart, right: canvasEnd, top: canvasTop, bottom: canvasBottom} = canvasBoundary;

    const externalCanvasWidth = canvasEnd - canvasStart;
    const externalCanvasHeight = canvasBottom - canvasTop;

    // Get or compute cursor location in client coordinates
    let clientX, clientY;

    if (event.clientY) {
      clientX = event.clientX;
      clientY = event.clientY;
    } else if (event.touches) {
      clientX = event.touches[0].clientX;
      clientY = event.touches[0].clientY;
    } else {
      clientX = event.pageX - window.scrollX;
      clientY = event.pageY - window.scrollY;
    }

    // Convert to internal canvas coordinates
    let canvasX = (clientX - canvasStart) / externalCanvasWidth * this.canvas.width;
    let canvasY = (clientY - canvasTop) / externalCanvasHeight * this.canvas.height;

    // Ensure cursor is overtop of canvas
    if (constrain) {
      canvasX = clamp(canvasX, 0, this.canvas.width);
      canvasY = clamp(canvasY, 0, this.canvas.height);
    } else {
      if (canvasX < 0 || canvasX > this.canvas.width) return;
      if (canvasY < 0 || canvasY > this.canvas.height) return;
    }

    return {x: canvasX, y: canvasY};
  }

  nearestHandleId(event) {
    let cursor = this.localizeCursorLocation(event);

    if (!cursor) return;

    const handleDistanceById = this.handles
      .map((handle, id) => ({ id, distance: distanceBetween(this.#transformPosition(handle), cursor) }));
    const {distance, id} = handleDistanceById.reduce((a, b) => a.distance < b.distance ? a : b);

    const handleDistanceLimit = 120;

    if (distance < handleDistanceLimit) return id
  }

  moveHandle(handleId, position) {
    this.handles[handleId] = this.#untransformPosition(position);

    // TODO: Reorder handles to avoid crossover or rotation
    this.render({outline: "rgb(13, 153, 255, 0.5)", selectedHandleId: handleId});
  }

  onLoad(callback) {
    this.onLoadCallback = callback;
  }

  // TODO: Refactor
  dragCallbacks = d3.drag()
    // Inject subject object into event object for the callbacks
    .subject((event) => {
      const selectedHandleId = this.nearestHandleId(event.sourceEvent);

      if (selectedHandleId != null) return {selectedHandleId};
    })
    // Re-draw magnifier & interface while dragging
    .on("drag", (event) => {
      const { selectedHandleId } = event.subject;
      const interfaceLocation = this.localizeCursorLocation(event.sourceEvent, true);

      if (!interfaceLocation) return;

      const cursorSource = event.sourceEvent.touches && event.sourceEvent.touches[0] || event.sourceEvent;
      const pageLocation = {x: cursorSource.pageX, y: cursorSource.pageY};

      this.moveHandle(selectedHandleId, interfaceLocation);
      this.rectifier.magnifier.render(this.canvas, this.#untransformPosition(interfaceLocation), pageLocation);
    })
    // Close magnifier, validate geometry, rectify
    .on("end", (_event) => {
      this.rectifier.magnifier.close();

      const canRectify = this.rectifier.rectify(false);
      this.rectifier.onUpdateCallback({valid: canRectify});

      if (canRectify) {
        this.render();
      } else {
        this.renderForFailedState();
      }
    });

  zoomCallbacks = d3.zoom()
    .scaleExtent([1, 4])
    .on("zoom", (event) => {
      const {transform} = event;

      this.render({transform});
    }).on("end", (event) => {
      const {x: panX, y: panY, k: scale} = event.transform;
      let xReset, yReset;

      // Default top-left & bottom-right image position
      const {x: xS, y: yS} = this.#transformPosition({ x: 0, y: 0 });
      const {x: xE, y: yE} = this.#transformPosition({ x: this.canvas.width, y: this.canvas.height });

      if (Math.round(xS) > 0) {
        xReset = 0;
      } else if (Math.ceil(xE) < this.canvas.width) {
        const xShift = this.canvas.width - Math.ceil(xE);
        xReset = Math.ceil(panX + xShift);
      }

      if (Math.round(yS) > 0) {
        yReset = 0;
      } else if (Math.ceil(yE) < this.canvas.height) {
        const yShift = this.canvas.height - Math.ceil(yE);
        yReset = Math.ceil(panY + yShift);
      }

      const eventIsArtificial = !event.sourceEvent;

      if (xReset !== undefined || yReset !== undefined) {
        if (xReset === undefined) xReset = panX;
        if (yReset === undefined) yReset = panY;
        let kReset = scale;

        if (eventIsArtificial) kReset += 0.05;

        const t = d3.zoomIdentity.translate(xReset, yReset).scale(kReset);

        d3.select(this.canvas)
          .transition()
          .duration(500)
          .ease(d3.easeLinear) // .easeQuad
          .call(this.zoomCallbacks.transform, t);
      } else {
        const canRectify = this.rectifier.rectify(false);
        this.rectifier.onUpdateCallback({valid: canRectify});

        if (!canRectify) this.renderForFailedState();
      }
    });

  // Shift coordinate to account for pan
  #transformPosition(point) {
    if (!this.d3Transform) return point;

    const xScale = d3.scaleLinear();
    const yScale = d3.scaleLinear();

    const rescaledX = this.d3Transform.rescaleX(xScale);
    const rescaledY = this.d3Transform.rescaleY(yScale);

    return { x: rescaledX(point.x), y: rescaledY(point.y) }
  }

  // Stop accounting for pan
  #untransformPosition(point) {
    if (!this.d3Transform) return point;

    const unPoint = this.d3Transform.invert([point.x, point.y]);

    return { x: unPoint[0], y: unPoint[1] }
  }
}

class Magnifier {
  backgroundWidth;
  backgroundHeight;

  constructor() {
    this.element = document.createElement("div");
    this.reticle = document.createElement("div");
    this.focalDot = document.createElement("div");
    this.size = 140;
    this.zoom = 3.0;
  }

  load(imageUrl, width, height) {
    // Set imageUrl as background image
    // TODO: Consider scaling background image by zoom level

    this.backgroundWidth = width;
    this.backgroundHeight = height;

    // Define magnifier element
    this.element.style.borderStyle = "solid";
    this.element.style.borderWidth = "2px";
    this.element.style.borderColor = "rgb(255, 255, 255)";
    this.element.style.position = "absolute";
    this.element.style.display = "none";
    this.element.style.zIndex = "101";
    this.element.style.width = `${this.size}px`;
    this.element.style.height = `${this.size}px`;
    this.element.style.borderRadius = "50%";
    this.element.style.backgroundColor = "#000";
    this.element.style.backgroundImage = "url('" + imageUrl + "')";
    this.element.style.backgroundRepeat = "no-repeat";
    this.element.style.backgroundSize = `${width}px ${height}px`;

    // Define reticle element
    this.reticle.style.borderStyle = "solid";
    this.reticle.style.borderWidth = "2px";
    this.reticle.style.borderColor = "rgb(255, 255, 255)";
    this.reticle.style.position = "relative";
    this.reticle.style.zIndex = "102";
    this.reticle.style.width = `${this.size / 4}px`;
    this.reticle.style.height = `${this.size / 4}px`;
    this.reticle.style.marginTop = `${140 /2 - 20}px`;
    this.reticle.style.marginLeft = `${140 /2 - 20}px`;
    this.reticle.style.borderRadius = "50%";

    // Define focal dot element
    this.focalDot.style.borderStyle = "solid";
    this.focalDot.style.borderWidth = "2px";
    this.focalDot.style.borderColor = "rgb(255, 255, 255)";
    this.focalDot.style.backgroundColor = "rgb(255, 255, 255)"
    this.focalDot.style.position = "absolute";
    this.focalDot.style.zIndex = "103";
    this.focalDot.style.width = `${this.size / 25}px`;
    this.focalDot.style.height = `${this.size / 25}px`;
    this.focalDot.style.marginTop = `50%`;
    this.focalDot.style.marginLeft = `50%`;
    this.focalDot.style.transform = "translate(-50%, -50%)";
    this.focalDot.style.borderRadius = "50%";

    document.body.appendChild(this.element);
    this.element.appendChild(this.reticle);
    this.reticle.appendChild(this.focalDot);
  }

  render(source, sourceLocation, pageLocation) {
    // Offset background proportionally to handle position

    const xPercent = sourceLocation.x / source.width;
    const yPercent = sourceLocation.y / source.height;
    const x = this.backgroundWidth * xPercent;
    const y = this.backgroundHeight * yPercent;

    this.element.style.left = (pageLocation.x - this.size / 2) + "px";
    this.element.style.top = (pageLocation.y - this.size - 40) + "px";
    this.element.style.backgroundPosition = `${this.size / 2 - x}px ${this.size / 2 - y}px`;
    this.element.style.display = "block";
  }

  close() {
    this.element.style.display = "none";
  }
}

export default class Rectifier {
  onLoadCallback = () => {};
  onUpdateCallback = () => {};
  beforeRectifyCallback = () => {};
  failedStateIndicatable = false;

  constructor({imageUrl, canvas}) {
    this.imageUrl = imageUrl;

    this.interface = new Interface(canvas);
    this.magnifier = new Magnifier();
  }

  load(preferredHandlePositions) {
    fetchImage(this.imageUrl)
      .then((image) => {
        this.inputImage = image;

        this.interface.onLoad(() => {
          this.onLoadCallback();
        });

        const {width, height} = this.inputImage;

        this.interface.load(this.inputImage, preferredHandlePositions, this);
        this.magnifier.load(this.imageUrl, width, height);
      })
      .catch(() => {
        alert("Failed to load image!");
      });
  }

  rectify(render = true) {
    // Scale handle coordinates to input image
    const scale = this.interface.scale;
    const inputCorners = this.interface.handles.map((point) => ({x: point.x / scale, y: point.y / scale}));
    const outputDimensions = rectifiedDimensions(this.inputImage.width, this.inputImage.height, inputCorners);
    const { width: outputWidth, height: outputHeight } = outputDimensions;

    // Ensure valid geometry
    const canRectify = !!(outputWidth && outputHeight);
    if (!canRectify) this.interface.renderForFailedState();

    if (!render || !canRectify) return canRectify;

    this.beforeRectifyCallback();

    let inputPixels = cv.imread(this.inputImage);
    let outputPixels = new cv.Mat();
    let outputSize = new cv.Size(outputWidth, outputHeight);

    let inputPoints = pointsToMat(inputCorners);
    let outputPoints = cv.matFromArray(4, 1, cv.CV_32FC2, [0, 0, outputWidth, 0, outputWidth, outputHeight, 0, outputHeight]);
    let transformationMatrix = cv.getPerspectiveTransform(inputPoints, outputPoints);

    const rectificationCanvas = document.createElement("canvas");

    cv.warpPerspective(inputPixels, outputPixels, transformationMatrix, outputSize, cv.INTER_LINEAR, cv.BORDER_CONSTANT, new cv.Scalar());
    cv.imshow(rectificationCanvas, outputPixels);

    inputPixels.delete(); outputPixels.delete(); transformationMatrix.delete(); inputPoints.delete(); outputPoints.delete();

    return new Promise((resolve) => {
      rectificationCanvas.toBlob((blob) => {
        const file = new File([blob], "rectified.png", { type: "image/png" });
        rectificationCanvas.remove();
        resolve({file, corners: inputCorners});
      });
    });
  }

  onLoad(callback) {
    this.onLoadCallback = callback;
  }

  onUpdate(callback) {
    this.onUpdateCallback = callback;
  }

  beforeRectify(callback) {
    this.beforeRectifyCallback = callback;
  }
}
