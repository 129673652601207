import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
    static targets = ["column"];

    unsortableColumnIDs = ["ppis_of_unlocked_zips", "ppis_of_locked_zips", "active_dates"];

    connect() {
        this.#addSortDirectionClasses()
    }

    columnTargetConnected(column) {
        // TODO: Find a way to parameterize which columns aren't sortable
        if (!this.unsortableColumnIDs.includes(column.id)) {
            column.addEventListener("click", () => {
                const ascending = !column.classList.contains('asc');

                this.#sortTable(column, ascending)
            });
        }
    }

    #addSortDirectionClasses() {
        const currentUrl = window.location.href;

        // Check if the 'sort' and 'direction' parameters exist in the URL
        const sortParam = this.#getQueryParameter(currentUrl, 'sort');
        const directionParam = this.#getQueryParameter(currentUrl, 'direction');

        if (sortParam !== null && directionParam !== null) {
            const column = document.getElementById(sortParam);

            if (directionParam === 'asc') {
                column.classList.add('asc');
                column.firstElementChild.classList.add('bi-arrow-up');
            } else {
                column.classList.add('desc');
                column.firstElementChild.classList.add('bi-arrow-down');
            }
        }
    }

    #sortTable(column, ascending) {
        const sortingParams = {
            sort: column.id,
            direction: ascending ? 'asc' : 'desc'
        };

        const urlObject = new URL(window.location.href);

        // Add sort and direction params and keep existing params
        for (const key in sortingParams) {
            urlObject.searchParams.set(key, sortingParams[key]);
        }

        Turbo.visit(urlObject.toString())
    }

    #getQueryParameter(url, parameterName) {
        const queryParams = new URLSearchParams(new URL(url).search);
        return queryParams.get(parameterName);
    }
}
