import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    entryClass: String,
    template: String,
  };

  connect() {
  }

  duplicate(e) {
    e.preventDefault();
    this.element.insertAdjacentHTML('beforeend', this.templateValue);
  }

  remove(e) {
    e.preventDefault();

    const parentEntrySelector = `.${this.entryClassValue}`;
    const parentEntry = e.target.closest(parentEntrySelector);

    parentEntry.remove();
  }
}
