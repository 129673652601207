import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import consumer from "../channels/consumer";

export default class extends Controller {
  static targets = ['progressBar', 'statusDescription'];

  static values = {
    stitchId: Number,
    onCompletePath: String,
    percentComplete: Number,
  };

  stitchChannelSubscription;

  connect() {
    this.#createStitchChannelSubscription();
  }

  disconnect() {
    this.stitchChannelSubscription.unsubscribe();
  }

  percentCompleteValueChanged(currentValue, oldValue) {
    this.#updateProgressBar();

    // Avoid infinite loops
    if (currentValue === oldValue) return;

    if (currentValue === -1) {
      window.location.reload();
    } else if (currentValue === 100) {
      Turbo.visit(this.onCompletePathValue);
    }
  }

  #updateProgressBar() {
    const progressBarWidth = this.percentCompleteValue > 5 ? this.percentCompleteValue : 5;
    this.progressBarTarget.style.width = `${progressBarWidth}%`;
  }

  #updateStatusDescription(description) {
    this.statusDescriptionTarget.textContent = description;
  }

  // ==== ActionCable Event Callbacks ====

  #createStitchChannelSubscription() {
    const controller = this;

    const stitchChannelIdentifier = {
      channel: "StitchChannel",
      stitch_id: this.stitchIdValue,
    }

    this.stitchChannelSubscription = consumer.subscriptions.create(
      stitchChannelIdentifier,
      {
        received(event) {
          const eventName = event.event;
          const eventData = event.data;

          switch (eventName) {
            case "percent_complete_updated":
              controller.percentCompleteDidUpdate(eventData);
              break;
            case "failed":
              controller.stitchDidFail(eventData);
              break;
            default:
              break;
          }
        },
      }
    );
  }

  percentCompleteDidUpdate(eventData) {
    const percentComplete = parseInt(eventData.percent_complete, 10);
    this.percentCompleteValue = percentComplete;
  }

  stitchDidFail(eventData) {
    const errorCode = eventData.code;

    const failureDescription = `Error: ${errorCode}`;

    this.#updateStatusDescription(failureDescription)

    window.location.reload();
  }
}
