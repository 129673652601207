import "javascript/stitches/composite_image";
import "javascript/stitches/new_reference_image_form";
import "javascript/stitches/edit_reference_image_form";
import "javascript/admin_content";
import Rails from "@rails/ujs";

document.addEventListener("turbo:load", () => {
  const editStitchNameModal = document.getElementById("edit-stitch-name-modal");

  if (editStitchNameModal) {
    editStitchNameModal.addEventListener("shown.bs.modal", () => {
      editStitchNameModal.querySelector("input#stitch_name").focus();
    });
  }

  const userSelectForm = document.getElementById("record-id-filter");
  if (userSelectForm) {
    const userSelect = userSelectForm.querySelectorAll("#user-select")[0];
    userSelect.addEventListener("change", () => {
      Rails.fire(userSelectForm, "submit");
    });
  }

  const newStitchForm = document.getElementById("new-stitch-form");
  if (newStitchForm) {
    const heightField = document.getElementById("stitch_canvas_height");
    const widthField = document.getElementById("stitch_canvas_width");

    heightField.addEventListener("keypress", (event) => {
      if (!/^\.|\d$/.test(event.key)) event.preventDefault();
    });

    widthField.addEventListener("keypress", (event) => {
      if (!/^\.|\d$/.test(event.key)) event.preventDefault();
    });
  }
});
