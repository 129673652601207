import { Controller } from "@hotwired/stimulus";
import { fetchImage } from "../javascript/utils/images";
import CaptureGuideRenderer from "../javascript/utils/capture_guide_renderer"

export default class extends Controller {
  static targets = ["canvas"];

  static values = {
    imageUrl: String,
    rowCount: Number,
    columnCount: Number,
    horizontalOverlap: Number,
    verticalOverlap: Number,
  };

  captureGuideWriter;

  connect() {
    fetchImage(this.imageUrlValue)
      .then((image) => {
        this.#loadCaptureGuideWriter(image);
      })
      .catch(() => {
        alert("Failed to load image!");
      });
  }

  showTutorial(event) {
    event.stopPropagation();
    const slideDeckController = this.application.controllers.find((c) => c.identifier === "slide-deck");
    slideDeckController.open();
  }

  #loadCaptureGuideWriter(image) {
    this.captureGuideWriter = new CaptureGuideRenderer({
      canvas: this.canvasTarget,
      rowCount: this.rowCountValue,
      columnCount: this.columnCountValue,
      horizontalOverlap: this.horizontalOverlapValue,
      verticalOverlap: this.verticalOverlapValue,
    });

    this.captureGuideWriter.onLoad(() => {
      this.#hideSpinner();
      this.canvasTarget.classList.add("z-2");
    });

    this.captureGuideWriter.load(image);
  }

  #hideSpinner() {
    const spinnerController = document.querySelector("[data-controller='spinner-overlay']");
    spinnerController.dataset.spinnerOverlayVisibleValue = "false";
  }
}
