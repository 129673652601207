import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import Rails from "@rails/ujs";
import CheckoutForm from "../javascript/stitches/checkout_form";

export default class extends Controller {
  static targets = ["creditCardForm"];

  static values = {
    afterPaySuccessPath: String,
    invoiceDataPath: String,
    stripeKey: String,
  };

  checkoutForm = null;

  connect() {
    this.showInvoiceTotal();

    if (this.hasCreditCardFormTarget) {
      this.creditCardFormTarget.addEventListener("submit", (e) => Rails.stopEverything(e));
    }

    Rails.ajax({
      url: this.invoiceDataPathValue,
      type: "get",
      success: (data) => {
        if (this.hasCreditCardFormTarget) {
          this.loadStripeElements(data);
        }
        this.showInvoiceTotal(data);
      },
    });
  }

  showInvoiceTotal(stripeInvoiceData = undefined) {
    let { invoiceTotal } = window;

    if (this.checkoutForm) {
      invoiceTotal = this.checkoutForm.total;
    } else if (stripeInvoiceData) {
      const { total } = stripeInvoiceData;

      invoiceTotal = total / 100.0;
    }

    if (invoiceTotal) {
      const invoiceTotalLabel = document.getElementById("unlock-price-label");
      invoiceTotalLabel.textContent = `$${invoiceTotal.toFixed(2)}`;
    }

    window.invoiceTotal = invoiceTotal;
  }

  payWithCard(event) {
    event.preventDefault();

    this.checkoutForm.disableSubmit("Loading..");

    this.checkoutForm.payWithCard().then((result) => {
      const { error, elementType } = result;

      if (elementType) {
        this.checkoutForm.getStripeElementField(elementType).focus();
      }

      if (error) {
        this.checkoutForm.enableSubmit("Checkout");
        this.showError(error.message);
      } else {
        Turbo.visit(this.afterPaySuccessPathValue);
      }
    });
  }

  loadStripeElements(stripeData) {
    this.checkoutForm = new CheckoutForm({
      key: this.stripeKeyValue,
      formElement: this.creditCardFormTarget,
      submitElement: this.creditCardFormTarget.querySelector("#pay-button"),
      stripeInvoiceData: stripeData,
    });

    this.checkoutForm.loadStripeElements().then((form) => {
      form.mountCvcField("#card-cvc");
      form.mountNumberField("#card-number");
      form.mountExpiryField("#card-expiry");
    });

    if (this.checkoutForm.couponName) {
      const promoController = this.application.controllers.find((c) => c.identifier === "promo");
      promoController.couponNameValue = this.checkoutForm.couponName;
    }
  }

  showError = (errorMessage) => {
    const errorBox = document.getElementById("errors");
    errorBox.classList.remove("d-none");

    const errorListItem = document.createElement("li");
    errorListItem.classList.add("list-group-item");
    errorListItem.classList.add("border-danger");
    errorListItem.textContent = errorMessage;

    const errorList = errorBox.firstElementChild;
    errorList.replaceChildren(errorListItem);
  };
}
