import { Controller } from "@hotwired/stimulus";
import enableProgressiveFields from "../javascript/progressive_fields";

export default class extends Controller {
  static targets = ["areaCodeField", "localPrefixField", "localSuffixField"];

  static values = {
    hostFieldId: String
  };

  connect() {
    const telephoneFields = this.element.querySelectorAll("input[type='tel']");

    enableProgressiveFields(telephoneFields);
    this.#updateTelephoneFields();

    telephoneFields.forEach((telephoneField) => {
      telephoneField.addEventListener("keypress", (event) => {
        if (!/^\d$/.test(event.key)) event.preventDefault();
      });

      telephoneField.addEventListener("change", () => {
        this.#updateHostField();
      });
    });
  }

  #updateTelephoneFields() {
    const phoneNumber = this.hostField.value.toString();

    this.areaCodeFieldTarget.value = phoneNumber.slice(0, 3);
    this.localPrefixFieldTarget.value = phoneNumber.slice(3, 6);
    this.localSuffixFieldTarget.value = phoneNumber.slice(6);
  };

  #updateHostField() {
    const phoneNumber = `${this.areaCodeFieldTarget.value}${this.localPrefixFieldTarget.value}${this.localSuffixFieldTarget.value}`;

    this.hostField.value = phoneNumber;
  };

  get hostField() {
    return document.getElementById(this.hostFieldIdValue);
  }
}
