import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import consumer from "../channels/consumer";

// DEPRECATED: Use composition-progress controller instead

export default class extends Controller {
  static values = {
    afterStitchCompletePath: String,
  };

  connect() {
    const stitchController = this;

    this.subscription = consumer.subscriptions.create(
      {
        channel: "StitchChannel",
        stitch_id: stitchController.element.dataset.stitchId,
      },
      {
        connected() {
          stitchController.updateProgressBar(stitchController.element.dataset.percentComplete);
        },
        received(event) {
          const eventName = event.event;
          const eventData = event.data;

          switch (eventName) {
            case "percent_complete_updated":
              stitchController.percentCompleteDidUpdate(eventData);
              break;
            case "failed":
              stitchController.stitchDidFail(eventData);
              break;
            default:
              break;
          }
        },
      }
    );
  }

  disconnect() {
    this.subscription.unsubscribe();
  }

  percentCompleteDidUpdate(eventData) {
    const percentComplete = parseInt(eventData.percent_complete, 10);
    if (percentComplete === -1) window.location.reload();

    this.updateProgressBar(percentComplete);

    if (percentComplete === 100) Turbo.visit(this.afterStitchCompletePathValue);
  }

  stitchDidFail(eventData) {
    const errorCode = eventData.code;

    this.element.textContent = `Error: ${errorCode}`;
    window.location.reload();
  }

  updateProgressBar(percentComplete) {
    const progressLabel = this.element.querySelector("#progress-label");
    const progressBar = this.element.querySelector(".progress-bar");

    progressLabel.textContent = `Progress: ${percentComplete}%`;
    progressBar.style.width = `${percentComplete}%`;
  }
}
