import { Controller } from "@hotwired/stimulus";
import Tooltip from "bootstrap/js/src/tooltip";
import NewSegmentPhotoManager from "../javascript/new_segment_photo_manager";

export default class extends Controller {
  static targets = [
    "filesField", "decoyFilesField", "dropZone", "stagingArea",
    "startZipButton", "persistedPhotoPreview", "fileNotReadySpinner",
    "photoLimitAlert"
  ];

  static values = {
    createSegmentPhotoUrl: String,
    recommendedPhotoLimit: Number,
  };

  newSegmentPhotoManagers = [];
  startZipButtonTooltip;
  directUploadUrl;
  csrfToken;

  connect() {
    this.csrfToken = document.head.querySelector("meta[name='csrf-token']").content;
    this.directUploadUrl = this.filesFieldTarget.dataset.directUploadUrl;

    this.startZipButtonTooltip = Tooltip.getOrCreateInstance(this.startZipButtonTarget, { trigger: "manual" });
    this.updateStartZipButton();
  }

  persistedPhotoPreviewTargetConnected() {
    this.updatePhotoLimitAlert();
    this.updateStartZipButton();
  }

  persistedPhotoPreviewTargetDisconnected() {
    this.updatePhotoLimitAlert();
    this.updateStartZipButton();
  }

  fileNotReadySpinnerTargetConnected(spinner) {
    const persistedPhotoFrame = spinner.closest("turbo-frame");
    const persistedPhotoFrameSrc = spinner.dataset.frameSrc;

    setTimeout(() => {
      persistedPhotoFrame.src = persistedPhotoFrameSrc;
    }, 3000);
  }

  openFilesDialog() {
    this.filesFieldTarget.click();
  }

  addHighlightDropZone(event) {
    event.preventDefault();
    this.dropZoneTarget.classList.add("awaiting-drop");
  }

  removeHighlightDropZone() {
    this.dropZoneTarget.classList.remove("awaiting-drop");
  }

  transferFilesToFilesField(event) {
    event.preventDefault();
    this.removeHighlightDropZone();

    const droppedFiles = event.dataTransfer.files;

    if (droppedFiles.length < 1) return;

    this.filesFieldTarget.files = droppedFiles;

    const changeEvent = new Event("change");
    this.filesFieldTarget.dispatchEvent(changeEvent);
  }

  beginUploads() {
    Array.from(this.filesFieldTarget.files).forEach((file) => {
      this.#manageSegmentPhotoFile(file);
    });

    // Reset the file input field
    this.filesFieldTarget.setAttribute("value", null);
  }

  showStartZipTooltip(event) {
    if (!this.startZipButtonTooltipText) return;

    event.preventDefault();
    this.startZipButtonTooltip.show();

    setTimeout(() => {
      this.startZipButtonTooltip.hide();
    }, 3000);
  }

  #manageSegmentPhotoFile(file) {
    const manager = new NewSegmentPhotoManager({
      createSegmentPhotoUrl: this.createSegmentPhotoUrlValue,
      directUploadUrl: this.directUploadUrl,
      csrfToken: this.csrfToken,
      domFile: file,
      onFail: () => this.updateStartZipButton(),
      onSucceed: () => this.updateStartZipButton(),
    });

    this.newSegmentPhotoManagers.push(manager);
    manager.renderStatusCard(this.stagingAreaTarget);
    manager.processFile();
    this.updateStartZipButton();
  }

  updatePhotoLimitAlert() {
    if (!this.recommendedPhotoLimitValue) return;

    if (this.persistedPhotoCount > this.recommendedPhotoLimitValue) {
      this.photoLimitAlertTarget.classList.remove("d-none");
    } else {
      this.photoLimitAlertTarget.classList.add("d-none");
    }
  }

  updateStartZipButton() {
    const defaultButtonText = "Start Zip Now";

    if (this.activeUploadsCount > 0) {
      this.startZipButtonTarget.value = "Loading Images";
      this.startZipButtonTarget.classList.add("disabled");
      this.startZipButtonTooltipText = "Upload in progress";
    } else if (this.persistedPhotoCount < 2) {
      this.startZipButtonTarget.value = defaultButtonText;
      this.startZipButtonTarget.classList.add("disabled");
      this.startZipButtonTooltipText = "Upload photos to continue";
    } else {
      this.startZipButtonTarget.value = defaultButtonText;
      this.startZipButtonTarget.classList.remove("disabled");
      this.startZipButtonTooltipText = "";
    }
  }

  set startZipButtonTooltipText(tooltipText) {
    this.startZipButtonTarget.setAttribute("data-bs-original-title", tooltipText);
  }

  get startZipButtonTooltipText() {
    return this.startZipButtonTarget.dataset.bsOriginalTitle;
  }

  get persistedPhotoCount() {
    return this.persistedPhotoPreviewTargets.length;
  }

  get activeUploadsCount() {
    const busyManagers = this.newSegmentPhotoManagers.filter((manager) => manager.processing);
    return busyManagers.length;
  }
}
