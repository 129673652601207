import { Controller } from "stimulus";
import PhotoSwipe from "photoswipe";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import { attachCaptionBox, customizePreloader } from "../javascript/lightboxes";

export default class extends Controller {

  static targets = ["trigger"];
  static values = {
    loadingMessage: { type: String, default: "Loading Your Preview" },
  };

  options = {
    gallery: this.triggerTargets,
    pswpModule: PhotoSwipe,
    preloaderDelay: 0
  };

  connect() {
    const lightbox = new PhotoSwipeLightbox(this.options);

    customizePreloader(lightbox, this.loadingMessageValue);
    attachCaptionBox(lightbox);

    lightbox.init();
  }
}
