import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "globalScope", "narrowScope", "submitButton"];

  connect() {
    this.formTarget.reset();
  }

  useScope() {
    this.globalScopeTarget.checked = false;

    this.submit();
  }

  toggleScopes({ target: toggleScopeBox }) {
    const useScopes = !toggleScopeBox.checked;

    this.narrowScopeTargets.forEach((scopeBox) => {
      scopeBox.checked = useScopes;
    });

    this.submit();
  }

  submit() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.click();
    } else {
      this.formTarget.requestSubmit();
    }
  }
}
