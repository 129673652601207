import { Controller } from "stimulus";
import generateRandomString from "../javascript/utils/random_string_generator";

export default class extends Controller {
  // eslint-disable-next-line class-methods-use-this
  generate({ params: { target } }) {
    const integrationToken = generateRandomString(32);
    const targetElement = document.getElementById(target);

    targetElement.value = integrationToken;
  }
}
