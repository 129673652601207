// Provides `handleBubbledEvent()` as an alternative to `addEventListener()` which
// does not trigger reactions for elements rendered after the the listener was added.
//
// Works by listening on a designated "anchor" element for events as they propagate
// up the DOM tree. If an event of the specified type targets the specified element,
// the reaction function is triggered.

const handleBubbledEvent = (eventType, anchorSelector, targetSelector, reaction) => {
  const anchorElement = document.querySelector(anchorSelector);

  anchorElement.addEventListener(eventType, (event) => {
    if (!event.target.matches(targetSelector)) return;
    reaction(event);
  });
};

export default handleBubbledEvent;
