import PhotoSwipeLightbox from "photoswipe/lightbox";
import PhotoSwipe from "photoswipe";

export const enableLightboxes = (triggerSelector) => {
  // TODO: deprecate in favor of using the stimulus controller lightbox_controller.js
  triggerSelector ||= ".lightbox-trigger";

  const triggers = document.querySelectorAll(triggerSelector);
  if (triggers.length === 0) return;

  const options = {
    gallery: triggerSelector,
    pswpModule: PhotoSwipe,
    preloaderDelay: 0
  };

  const lightbox = new PhotoSwipeLightbox(options);

  customizePreloader(lightbox);
  attachCaptionBox(lightbox);

  lightbox.init();
};

export const customizePreloader = (lightbox, message) => {
  const loadingMessage = message || "Loading Your Preview";

  lightbox.on("uiRegister", () => {
    const elementDefinitions = lightbox.pswp.ui.uiElementsData;
    const preloaderDefinition = elementDefinitions.find((definition) => definition.name === "preloader");
    const originalInitFunction = preloaderDefinition.onInit;

    let loadingLabel = null;

    preloaderDefinition.onInit = (element, pswp) => {
      if (!loadingLabel) {
        loadingLabel = document.createElement("span");

        loadingLabel.innerText = loadingMessage;
        loadingLabel.className = "preloader-banner-label";

        element.classList.add("preloader-banner");
        element.appendChild(loadingLabel);
      }

      originalInitFunction(element, pswp);
    }
  });
};

export const attachCaptionBox = (lightbox) => {
  lightbox.on("uiRegister", () => {
    lightbox.pswp.ui.registerElement({
      name: "caption",
      order: 9,
      isButton: false,
      appendTo: "root",
      onInit: (captionBox) => {
        lightbox.pswp.on("change", () => {
          const currentSlide = lightbox.pswp.currSlide.data.element;
          const caption = currentSlide.getAttribute("title");
          if (caption) {
            captionBox.innerHTML = caption;
            captionBox.classList.remove("d-none");
          } else {
            captionBox.innerHTML = "";
            captionBox.classList.add("d-none");
          }
        });
      }
    });
  });
};
