import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import * as d3 from "d3";
import { enableTooltips } from "../javascript/tooltips";

export default class extends Controller {
  static targets = ["container"];

  static values = {
    dataUrl: String,
    referrerViewUrl: String,
    unlockPrice: Number,
  };

  connect() {
    this.#fetchReferrerTreeData().then((referrerTreeData) => {
      const referrerTreeChart = this.#createReferrerTreeChart(referrerTreeData);
      this.containerTarget.append(referrerTreeChart);
      enableTooltips(".tooltip-anchor");
    });
  }

  #visitReferrerView(referrerId) {
    const referrerViewUrl = new URL(this.referrerViewUrlValue);
    referrerViewUrl.searchParams.set("id", referrerId);
    referrerViewUrl.searchParams.set("redirect", "true");

    Turbo.visit(referrerViewUrl.href);
  }

  async #fetchReferrerTreeData() {
    return d3.json(this.dataUrlValue);
  }

  #createReferrerTreeChart(data) {
    // Generate D3 Hierarchy
    let i = 0;
    const root = d3.hierarchy(data).eachBefore(d => d.index = i++);
    const nodes = root.descendants();


    // Define table column titles and contents
    const formatNumber = d3.format(",");
    const formatPercent = d3.format(".2%");
    const formatCurrency = d3.format("$.2f");

    const columns = [
      {
        label: "Referrers",
        description: "Number of direct sub-referrers",
        text: d => d.children?.length || "-",
        x: 250
      },
      {
        label: "Referrals",
        description: "Number of signed-up users that used referral link",
        text: ({data}) => data.user_referral_count ? formatNumber(data.user_referral_count) : "-",
        x: 310
      },
      {
        label: "Commission",
        description: "Value of future commissions on direct sales",
        text: ({data}) => formatCurrency(data.commission_rate_static ? data.commission_rate : data.commission_rate * this.unlockPriceValue),
        tooltip: ({data}) => data.commission_rate_static ? null : formatPercent(data.commission_rate),
        x: 390
      },
      {
        label: "Due",
        description: "Unpaid earnings on past commissions",
        text: ({data}) => data.unpaid_earnings > 0 ? formatCurrency(data.unpaid_earnings) : "-",
        x: 440
      }
    ];

    // Chart Container
    const nodeWidth = 700;
    const nodeHeight = 17;

    const chart = d3.create("svg")
      .attr("viewBox", [-nodeHeight / 2, -nodeHeight * 3 / 2, nodeWidth, (nodes.length + 1) * nodeHeight])
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("width", "1000px")
      .style("overflow", "visible");

    // Hierarchy Lines
    chart.append("g")
      .attr("fill", "none")
      .attr("stroke", "#999")
      .selectAll("path")
      .data(root.links())
      .join("path")
      .attr("d", d => `
        M${d.source.depth * nodeHeight}, ${d.source.index * nodeHeight}
        V${d.target.index * nodeHeight}
        h${nodeHeight}
      `);

    // Referrer Row
    const node = chart.append("g")
      .selectAll("g")
      .data(nodes)
      .join("g")
      .attr("transform", d => `translate(0, ${d.index * nodeHeight})`)
      .attr("class", "referrer-tree-row")
      .on("click", (_event, {data}) => {
        this.#visitReferrerView(data.record_id);
      });

    // Referrer Row Background
    node.append("rect")
      .attr("fill", "white")
      .attr("x", d => 4 + d.depth * nodeHeight)
      .attr("y", -nodeHeight / 2)
      .attr("width", d => nodeWidth - 250 - d.depth * nodeHeight)
      .attr("height", nodeHeight)
      .attr("class", "row-background tooltip-anchor")
      .attr("title", d => d.data.earns_commissions_for_parent ? null : 'Parent ineligible for commissions');

    // Bullet Icon
    node.append("circle")
      .attr("cx", d => d.depth * nodeHeight)
      .attr("r", 2.5)
      .attr("fill", d => d.data.earns_commissions_for_parent ? null : "#999");

    // Organization Name Cell
    node.append("text")
      .attr("class", "tooltip-anchor")
      .attr("title", d => d.ancestors().reverse().map(d => d.data.organization_name).join("/"))
      .attr("dy", "0.32em")
      .attr("x", d => d.depth * nodeHeight + 6)
      .attr("fill", d => d.data.earns_commissions_for_parent ? null : "#555")
      .text(d => d.data.organization_name);

    // Iterate over column settings to generate table entries
    for(const {label, description, text, tooltip = () => void 0, x} of columns) {
      chart.append("text")
        .attr("dy", "0.32em")
        .attr("y", -nodeHeight)
        .attr("x", x)
        .attr("text-anchor", "end")
        .attr("font-weight", "bold")
        .attr("class", "tooltip-anchor")
        .attr("title", description)
        .text(label);

      node.append("text")
        .attr("dy", "0.32em")
        .attr("x", x)
        .attr("text-anchor", "end")
        .attr("fill", d => d.data.earns_commissions_for_parent ? null : "#555")
        .attr("text-decoration", d => tooltip(d) && "underline")
        .attr("class", d => tooltip(d) && "tooltip-anchor")
        .attr("title", d => tooltip(d))
        .data(root.copy().descendants())
        .text(d => text(d))
    }

    return chart.node();
  }
}
