const perhapsProgressField = (currentField, progressiveFields) => {
  const currentPosition = parseInt(currentField.dataset.position, 10);
  const nextPosition = (currentPosition + 1).toString();
  const nextField = Array.from(progressiveFields).find(({ dataset }) => dataset.position === nextPosition);

  if (!nextField) return;

  if (currentField.value.length === currentField.maxLength) nextField.select();
};

const enableProgressiveFields = (progressiveFields) => {
  progressiveFields.forEach((progressiveInput) => {
    progressiveInput.addEventListener("keyup", (event) => {
      const { key, target: currentField } = event;

      if (!/^\w$/.test(key)) return;

      perhapsProgressField(currentField, progressiveFields);
    });
  });
};

export default enableProgressiveFields;
