import enableDurationFields from "../utils/duration_field";
import enablePersistedTabs from "../utils/persisted_tabs";
import generateRandomString from "../utils/random_string_generator";

const setupIntegrationIdArea = () => {
  const integrationIdArea = document.getElementById("integration-id-area");
  const integrationIdField = integrationIdArea.querySelector("#referrer_integration_id");
  const generateValueButton = integrationIdArea.querySelector(".generate-button");
  const clearValueButton = integrationIdArea.querySelector(".clear-button");

  if (generateValueButton) {
    generateValueButton.addEventListener("click", () => {
      integrationIdField.value = generateRandomString(8, /[a-z0-9]/i);
    });
  }

  if (clearValueButton) {
    clearValueButton.addEventListener("click", () => {
      integrationIdField.value = "";
    });
  }
};

const setupIntegrationTokenArea = () => {
  const integrationTokenArea = document.getElementById("integration-token-area");
  const integrationTokenField = integrationTokenArea.querySelector("#referrer_integration_token");
  const generateValueButton = integrationTokenArea.querySelector(".generate-button");
  const clearValueButton = integrationTokenArea.querySelector(".clear-button");

  generateValueButton.addEventListener("click", () => {
    integrationTokenField.value = generateRandomString(32);
  });

  clearValueButton.addEventListener("click", () => {
    integrationTokenField.value = "";
  });
};

document.addEventListener("turbo:load", () => {
  const referrerForm = document.getElementById("edit-referrer-form");
  const referrerCards = document.querySelectorAll(".referrer-card");

  // Admin::Referrers#edit
  if (referrerForm) {
    const applicationContainerSelector = "#application-container";

    enablePersistedTabs(applicationContainerSelector);
    enableDurationFields();

    setupIntegrationIdArea();
    setupIntegrationTokenArea();
  }

  // Admin::Referrers#index, #show
  if (referrerCards) {
    referrerCards.forEach((referrerCard) => {
      const referrerCardSelector = `#${referrerCard.id}`;
      enablePersistedTabs(referrerCardSelector);
    });
  }
});
