import { Controller } from "stimulus";
import Tooltip from "bootstrap/js/src/tooltip";

export default class extends Controller {
  destroy() {
    // eslint-disable-next-line no-restricted-globals
    const tooltip = Tooltip.getOrCreateInstance(event.target);
    tooltip.hide();

    this.element.remove();
  }
}
