const generateRandomString = (targetLength = 10, constraints = /./) => {
  let availableCharacters = Array.from("0123456789abcdefghiklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXTZ-_");
  availableCharacters = availableCharacters.filter((character) => constraints.test(character));

  const characterSampler = () => availableCharacters[Math.floor(Math.random() * availableCharacters.length)];
  const sampledCharacters = Array.from({ length: targetLength }, () => characterSampler());

  return sampledCharacters.join("");
};

export default generateRandomString;
