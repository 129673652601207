// Expects a clickable element to be present with a `data-duplicate` attribute storing a selector
// for the duplicatable field. The duplicated element's value will be cleared. Nested elements not supported.

const duplicateField = (fieldSelector) => {
  const duplicatableElementSelector = `${fieldSelector}:last-of-type`;
  const duplicatableElement = document.querySelector(duplicatableElementSelector);

  const duplicatedElement = duplicatableElement.cloneNode();
  duplicatedElement.value = "";
  duplicatableElement.insertAdjacentElement("afterend", duplicatedElement);
};

const enableFieldDuplication = () => {
  const duplicationButtons = document.querySelectorAll(`[data-duplicate]`);

  duplicationButtons.forEach((duplicationButton) => {
    duplicationButton.addEventListener("click", (event) => {
      const duplicatableElementSelector = event.target.dataset.duplicate;
      duplicateField(duplicatableElementSelector);
    });
  });
};

export default enableFieldDuplication;
