import enableDurationFields from "../utils/duration_field";
import enableFieldDuplication from "../utils/duplicatable_fields";

document.addEventListener("turbo:load", () => {
  const settingsForm = document.getElementById("settings-form");

  if (settingsForm) {
    enableDurationFields();
    enableFieldDuplication();
  }
});
