// Asynchronously retrieve image from URL as an Image object.
/* Example:
fetchImage("https://placekitten.com/500/500")
  .then((image) => {
    document.body.appendChild(image);
  })
  .catch((image) => {
    alert("Failed to load image!");
    console.dir(image);
  });
*/

export const fetchImage = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => resolve(image);
    image.onerror = () => reject(image);

    image.crossOrigin = "anonymous";
    image.src = url;
  });
};

// Re-scale an image to the specified width
/* Example:
TODO: Make example
*/
export const scaleImage = (inputImage, targetWidth) => {
  let canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  const originalWidth = inputImage.width;
  const originalHeight = inputImage.height;

  const aspectRatio = originalWidth / originalHeight;
  const targetHeight = targetWidth / aspectRatio;

  canvas.width = targetWidth;
  canvas.height = targetHeight;

  context.drawImage(inputImage, 0, 0, targetWidth, targetHeight);

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      const blobUrl = URL.createObjectURL(blob);
      const outputImage = new Image();

      outputImage.onerror = (event) => reject(event);

      outputImage.onload = () => {
        URL.revokeObjectURL(blobUrl); // Release blob for garbage collection
        resolve(outputImage);
      };

      outputImage.crossOrigin = "anonymous";
      outputImage.src = blobUrl;
    });
  });
};
