import cv from "@techstark/opencv-js"

export const distanceBetween = (a, b) => Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);

export const pointsToMat = (pts) => cv.matFromArray(pts.length, 1, cv.CV_32FC2, pts.flatMap(pt => [pt.x, pt.y]));

export const isConvex = (points) => cv.isContourConvex(pointsToMat(points));

export const rectifiedAspectRatio = (imageWidth, imageHeight, corners) => {
  const topLeft = corners[0];
  const topRight = corners[1];
  const bottomRight = corners[2];
  const bottomLeft = corners[3];

  const u0 = imageWidth / 2;
  const v0 = imageHeight / 2;

  const m1x = topLeft.x - u0;
  const m1y = topLeft.y - v0;
  const m2x = topRight.x - u0;
  const m2y = topRight.y - v0;
  const m3x = bottomLeft.x - u0;
  const m3y = bottomLeft.y - v0;
  const m4x = bottomRight.x - u0;
  const m4y = bottomRight.y - v0;

  const k2 =
    ((m1y - m4y) * m3x - (m1x - m4x) * m3y + m1x * m4y - m1y * m4x) /
    ((m2y - m4y) * m3x - (m2x - m4x) * m3y + m2x * m4y - m2y * m4x);

  const k3 =
    ((m1y - m4y) * m2x - (m1x - m4x) * m2y + m1x * m4y - m1y * m4x) /
    ((m3y - m4y) * m2x - (m3x - m4x) * m2y + m3x * m4y - m3y * m4x);

  // [See 3.2.3 - Singularity] If no perspective effect
  // TODO: Try replacing && with ||
  if (k2 === 1 && k3 === 1) {
    const aspectRatio = Math.sqrt(
      (Math.pow((m2y - m1y), 2) + Math.pow((m2x - m1x), 2)) /
      (Math.pow((m3y - m1y), 2) + Math.pow((m3x - m1x), 2)));
    return aspectRatio;
  }

  const fSquared =
    -((k3 * m3y - m1y) * (k2 * m2y - m1y) + (k3 * m3x - m1x) * (k2 * m2x - m1x)) /
    ((k3 - 1) * (k2 - 1));

  const aspectRatio = Math.sqrt(
    (Math.pow((k2 - 1), 2) + Math.pow((k2 * m2y - m1y), 2) / fSquared + Math.pow((k2 * m2x - m1x),2) / fSquared) /
    (Math.pow((k3 - 1), 2) + Math.pow((k3 * m3y - m1y), 2) / fSquared + Math.pow((k3 * m3x - m1x),2) / fSquared)
  );

  return aspectRatio;
};

export const rectifiedDimensions = (imageWidth, imageHeight, handleLocations) => {
  const aspectRatio = rectifiedAspectRatio(imageWidth, imageHeight, handleLocations);
  if (Number.isNaN(aspectRatio)) return {};

  if (!isConvex(handleLocations)) return {};

  const topLeft = handleLocations[0];
  const topRight = handleLocations[1];
  const bottomRight = handleLocations[2];
  const bottomLeft = handleLocations[3];

  const leftLength = distanceBetween(topLeft, bottomLeft);
  const rightLength = distanceBetween(topRight, bottomRight);
  let targetHeight = Math.max(leftLength, rightLength);
  let targetWidth = targetHeight * aspectRatio;

  // Rectified dimensions glitch as 100% width
  if (targetWidth === Infinity) targetWidth = imageWidth;
  if (targetHeight === Infinity) targetHeight = imageHeight;

  return { width: targetWidth, height: targetHeight };
};
