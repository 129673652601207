// Persisted Tabs
//
// Used for automatically showing the last used tab after reloading the page.
// Note: The last opened tab will not persist after leaving the page.
//
// Implementation:
//  Apply `.persisted-tabs` to a shared parent element of all the nav links.
//  Enable by importing and calling the `enablePersistedTabs` function.

import Tab from "bootstrap/js/src/tab";
import handleBubbledEvent from "./bubbled_event_handling";

const persistTab = (targetPaneSelector) => {
  const persistedTabSelector = `.persisted-tabs [data-bs-target="${targetPaneSelector}"]`;
  sessionStorage.setItem("persistedTabSelector", persistedTabSelector);
};

const showPersistedTab = () => {
  const persistedTabSelector = sessionStorage.getItem("persistedTabSelector");
  if (!persistedTabSelector) return;

  const persistedTab = document.querySelector(persistedTabSelector);

  if (!persistedTab) {
    sessionStorage.setItem("persistedTabSelector", null);
  } else {
    const bootstrapTab = Tab.getOrCreateInstance(persistedTab);
    bootstrapTab.show();
  }
};

const enablePersistedTabs = (anchorElementSelector) => {
  const persistableTabsSelector = `${anchorElementSelector} .persisted-tabs a[data-bs-toggle="tab"]`;

  handleBubbledEvent("shown.bs.tab", anchorElementSelector, persistableTabsSelector, (event) => {
    const { target: navLink } = event;
    const targetPaneSelector = navLink.dataset.bsTarget;
    persistTab(targetPaneSelector);
  });

  showPersistedTab();
};

export default enablePersistedTabs;
