import Rails from "@rails/ujs";

const showSpinnerOverlay = (coveredElement) => {
  const spinnerOverlay = document.createElement("div");
  const spinnerContainer = document.createElement("div");
  const spinner = document.createElement("div");

  spinnerOverlay.id = "spinner-overlay";
  spinnerContainer.id = "spinner-container";
  spinner.id = "spinner";

  if (coveredElement.querySelector(`#${spinnerOverlay.id}`)) return;

  spinnerOverlay.appendChild(spinnerContainer);
  spinnerContainer.appendChild(spinner);
  coveredElement.appendChild(spinnerOverlay);
};

const hideSpinnerOverlay = () => {
  const spinnerOverlay = document.getElementById("spinner-overlay");
  spinnerOverlay?.remove();
};

document.addEventListener("turbo:load", () => {
  const newReferenceImageForm = document.getElementById("new-reference-image-form");

  if (!newReferenceImageForm) return;

  const fileZone = newReferenceImageForm.querySelector(".file-zone");
  const referenceImageField = document.getElementById("unedited-reference-image-field");
  const referenceImageFieldDecoy = document.getElementById("unedited-reference-image-field-decoy");

  // Show highlight when dragging over drop area
  fileZone.addEventListener("dragover", (event) => {
    event.preventDefault();
    fileZone.classList.add("awaiting-drop");
  });

  // Remove highlight when dragging out of drop area
  fileZone.addEventListener("dragleave", () => {
    fileZone.classList.remove("awaiting-drop");
  });

  // Store file in reference image field when dropped
  fileZone.addEventListener("drop", (event) => {
    event.preventDefault();
    fileZone.classList.remove("awaiting-drop");
    showSpinnerOverlay(newReferenceImageForm);

    const tempFiles = new DataTransfer();
    const referenceImageFile = event.dataTransfer.files[0];

    if (referenceImageFile) {
      tempFiles.items.add(referenceImageFile);
      referenceImageField.files = tempFiles.files;

      const changeEvent = new Event("change");
      referenceImageField.dispatchEvent(changeEvent);
    } else {
      hideSpinnerOverlay();
      alert("Invalid file");
    }
  });

  // Submit form when file field changes
  referenceImageField.addEventListener("change", () => {
    showSpinnerOverlay(newReferenceImageForm);

    Rails.fire(newReferenceImageForm, "submit");
  });

  // Delegate click event to the actual file field
  referenceImageFieldDecoy.addEventListener("click", () => {
    referenceImageField.click();
  });
});
