// The admin panel is not yet feature complete, and we want to minimize UI differences for admins.
// Use `.hidden` to hide content, and include `.admin-content` to reveal it from `showAdminContent()`.
//
// Example:
// ```haml
// %button.admin-content.hidden Secret Button
// ```

const showAdminContent = () => {
  const hiddenAdminContent = document.querySelectorAll(".admin-content.hidden");

  hiddenAdminContent.forEach((hiddenContent) => {
    hiddenContent.classList.remove("hidden");
  });
};

document.addEventListener("turbo:load", () => {
  const adminContent = document.querySelectorAll(".admin-content");

  if (adminContent.length > 0) {
    // On "w" keydown, display admin-only content
    document.addEventListener("keydown", (event) => {
      const activeElementType = document.activeElement.tagName.toLowerCase();
      let key;
      if (event.key) {
        key = event.key.toLowerCase();
      }
      if (key === "w" && activeElementType !== "input") {
        showAdminContent();
      }
    });
  }
});
