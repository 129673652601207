import { scaleImage } from "./images";

// TODO: Consider computing referenceImageInset to improve UI accuracy

class PhotoBlock {
  constructor({startX, startY, width, height}) {
    this.startX = startX;
    this.startY = startY;
    this.width = width;
    this.height = height;
  }

  get centerPoint() {
    return {
      x: this.startX + this.width / 2,
      y: this.startY + this.height / 2,
    };
  }
}

export default class CaptureGuideRenderer {
  onLoadCallback = () => {console.dir("no load callback")};
  referenceImage;

  internalWidth = 800;
  referenceImageInset = 50;
  photoBlocks = [];

  constructor({canvas, rowCount, columnCount, horizontalOverlap, verticalOverlap}) {
    this.canvas = canvas;
    this.rowCount = rowCount;
    this.columnCount = columnCount;
    this.horizontalOverlap = horizontalOverlap;
    this.verticalOverlap = verticalOverlap;

    this.context = this.canvas.getContext("2d");
  }

  load(originalImage) {
    scaleImage(originalImage, this.internalWidth - this.referenceImageInset * 2)
      .then((rescaledImage) => {
        this.referenceImage = rescaledImage;

        this.canvas.width = this.internalWidth;
        this.canvas.height = this.referenceImage.height + this.referenceImageInset * 2;

        this.computePhotoBlocks();
        this.render();
        this.onLoadCallback();
      })
      .catch((_failureEvent) => {
        this.onLoadCallback();
      });
  }

  computePhotoBlocks() {
    const nonOverlapX = 1 - this.horizontalOverlap;
    const nonOverlapY = 1 - this.verticalOverlap;

    const photoWidth = this.canvas.width / (1 + this.columnCount * nonOverlapX - nonOverlapX);
    const photoHeight = this.canvas.height / (1 + this.rowCount * nonOverlapY - nonOverlapY);

    const photoCount = this.rowCount * this.columnCount;

    for (let id = 0; id < photoCount; id++) {
      const rowId = Math.floor(id / this.columnCount);

      let columnId = id % this.columnCount;
      if ((rowId + 1) % 2 === 0) columnId = this.columnCount - columnId - 1;

      const startY = rowId * photoHeight * nonOverlapY;
      const startX = columnId * photoWidth * nonOverlapX;

      const photoBlock = new PhotoBlock({startX, startY, width: photoWidth, height: photoHeight})
      this.photoBlocks.push(photoBlock);
    }
  }

  render(options = {}) {
    this.clear();
    this.renderBackground();
    this.renderReferenceImage();

    if (options.cycle !== undefined) {
      this.renderPhotoBlocks(options);
    } else {
      options.autoCycle = true;
      this.renderPhotoBlock(options);
    }
  }

  clear() {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  renderBackground() {
    this.context.fillStyle = "#000";
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }

  renderReferenceImage() {
    this.context.drawImage(this.referenceImage, this.referenceImageInset, this.referenceImageInset);
  }

  renderPhotoBlocks(options) {
    const finalCycle = options.cycle;

    for (let currentCycle = 0; currentCycle < finalCycle; currentCycle++) {
      this.renderPhotoBlock({cycle: currentCycle, fogOnly: true});
    }

    this.renderPhotoBlock({cycle: finalCycle, autoCycle: true});
  }

  renderPhotoBlock(options = {}) {
    const cycle = options.cycle || 0;
    const fogOnly = options.fogOnly || false;

    const photoBlock = this.photoBlocks[cycle];
    const {width, height, startX: x, startY: y} = photoBlock;

    // === Draw fog ===
    this.context.save();
    this.context.fillStyle = "rgba(255,255,255,0.25)";
    this.context.fillRect(x, y, width, height);
    this.context.restore();

    if (fogOnly) return;

    // === Draw outline ===
    this.context.save();

    const lineWidth = 6;

    this.context.lineWidth = lineWidth;
    this.context.strokeStyle = "rgba(255, 255, 255 ,0.75)";

    this.context.beginPath();
    this.context.setLineDash([20, 8]);

    this.context.lineTo(x, y);
    this.context.lineTo(x, y + height - lineWidth);
    this.context.lineTo(x + width, y + height - lineWidth);
    this.context.lineTo(x + width, y);

    this.context.closePath();
    this.context.stroke();
    this.context.restore();

    // === Draw counter bubble ===
    const {x: centerX, y: centerY} = photoBlock.centerPoint;

    this.context.fillStyle = "rgba(255, 255, 255, 0.75)";
    this.context.strokeStyle = "rgba(255, 255, 255, 0)";

    this.context.beginPath();
    this.context.arc(centerX, centerY, 50, 0, Math.PI * 2, true);
    this.context.fill();
    this.context.stroke();

    this.context.font = "50px Sans-Serif";
    this.context.fillStyle = "#000";
    this.context.textAlign = "center";
    this.context.fillText(String(cycle + 1), centerX, centerY + 15);

    // === Draw projection ===
    const projectionColor = "rgb(13, 153, 255)";
    const cameraX = this.canvas.width / 2;
    const cameraY = this.canvas.height / 2;

    this.context.strokeStyle = projectionColor;
    this.context.lineWidth = 3;

    this.context.beginPath();

    this.context.moveTo(x, y + height - lineWidth);
    this.context.lineTo(cameraX, cameraY);

    this.context.moveTo(x + width, y + height - lineWidth);
    this.context.lineTo(cameraX, cameraY);

    this.context.moveTo(x + width, y);
    this.context.lineTo(cameraX, cameraY);

    this.context.moveTo(x, y);
    this.context.lineTo(cameraX, cameraY);

    this.context.stroke();

    // === Draw camera icon ===
    const iconBackgroundColor = projectionColor;
    const iconCameraColor = "rgb(255, 255, 255)";
    const iconWidth = 80;
    const iconHeight = 60;

    // Background
    this.context.fillStyle = iconBackgroundColor;
    this.#renderCenteredRoundRect(cameraX, cameraY, iconWidth, iconHeight, 18);

    // Camera hump
    const humpX = cameraX - iconWidth / 2;
    const humpY = cameraY - iconHeight / 2 + 18;

    this.context.fillStyle = iconCameraColor;

    this.context.beginPath();
    this.context.moveTo(humpX + 51, humpY);
    this.context.lineTo(humpX + 47, humpY - 8);
    this.context.bezierCurveTo(humpX + 45, humpY - 8, humpX + 46, humpY - 8, humpX + 44, humpY - 9);
    this.context.lineTo(humpX + 36, humpY - 9);
    this.context.bezierCurveTo(humpX + 35, humpY - 8, humpX + 34, humpY - 8, humpX + 33, humpY - 8);
    this.context.lineTo(humpX + 29, humpY);
    this.context.fill();

    // Camera body
    this.context.fillStyle = iconCameraColor;

    this.#renderCenteredRoundRect(cameraX, cameraY + 2, 52, 30, 10);

    // Camera lens
    this.context.fillStyle = iconBackgroundColor;

    this.context.beginPath();
    this.context.arc(cameraX, cameraY, 8, 0, 2 * Math.PI);
    this.context.fill();

    if (!options.autoCycle) return;

    setTimeout(() => {
      const nextCycle = cycle + 1 < this.photoBlocks.length ? cycle + 1 : 0
      this.render({cycle: nextCycle});
    }, 1000);
  }

  onLoad(callback) {
    this.onLoadCallback = callback;
  }

  #renderCenteredRoundRect(centerX, centerY, width, height, radius) {
    const xStart = centerX - width / 2;
    const yStart = centerY - height / 2;
    const xEnd = xStart + width;
    const yEnd = yStart + height;

    this.context.beginPath();
    this.context.moveTo(xStart, yEnd);
    this.context.arcTo(xStart, yStart, xEnd, yStart, radius);
    this.context.arcTo(xEnd, yStart, xEnd, yEnd, radius);
    this.context.arcTo(xEnd, yEnd, xStart, yEnd, radius);
    this.context.arcTo(xStart, yEnd, xStart, yStart, radius);
    this.context.fill();
  }
}
