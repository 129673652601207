import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["applyPromoForm", "appliedCouponLabel"];

  static values = {
    couponName: String,
  };

  connect() {
    this.showCouponName();
  }

  disableSubmitButton() {
    const submitButton = this.applyPromoFormTarget.querySelector("input[type=submit]");

    submitButton.value = "Loading..";
    submitButton.disabled = true;
  }

  couponNameValueChanged() {
    this.showCouponName();
  }

  showCouponName() {
    if (this.couponNameValue.length === 0) return;

    this.appliedCouponLabelTarget.textContent = `Applied: ${this.couponNameValue}`;
  }
}
