import { Carousel } from "bootstrap";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["slide", "indicator", "deck"];

  static values = {
    autoShow: { type: Boolean, default: true },
    currentStep: Number,
  };

  finalStep;
  carousel;

  connect() {
    this.finalStep = this.slideTargets.length - 1;

    this.carousel = new Carousel(this.deckTarget, {
      interval: false, // Do not auto-progress
      wrap: false // Do not start over
    });

    document.addEventListener('click', ({ target }) => {
      if (!this.element.contains(target)) this.close();
    });

    if (this.autoShowValue !== true) {
      this.element.classList.add("d-none");
    }
  }

  updateStep({ to: currentStep }) {
    this.currentStepValue = currentStep;
  }

  // Params not added until v3.0
  continue({ target }) {
    const stepParam = target && target.dataset.slideDeckStepParam;

    if (stepParam) {
      this.carousel.to(stepParam);
    } else if (this.currentStepValue === this.finalStep ) {
      this.close();
    } else {
      this.carousel.next();
    }
  }

  open() {
    if (!this.isClosed) return;

    this.element.classList.add("slide-in-up");
    this.element.classList.remove("slide-out-down");
    this.element.classList.remove("d-none");
  }

  close() {
    if (this.isClosed) return;

    this.element.classList.remove("slide-in-up");
    this.element.classList.add("slide-out-down");
  }

  currentStepValueChanged() {
    this.indicatorTargets.forEach((indicator) => {
      const associatedStep = Number.parseInt(indicator.dataset.slideDeckStepParam, 10)

      if (associatedStep === this.currentStepValue) {
        indicator.classList.remove('bi-record');
        indicator.classList.add('bi-record-fill');
      } else {
        indicator.classList.add('bi-record');
      }
    });
  }

  autoShowValueChanged() {
    if (this.autoShowValue === false) return;

    this.open();
  }

  get isClosed() {
    if (this.element.getBoundingClientRect().height === 0) return true;
    if (this.element.classList.contains("slide-out-down")) return true;
    if (this.element.classList.contains("d-none")) return true;

    return false;
  }
}
