import { Controller } from "stimulus";
import Tooltip from "bootstrap/js/src/tooltip";

export default class extends Controller {
  static targets = ["field"];

  set({ currentTarget, params: { value } }) {
    this.fieldTarget.value = value;

    if (currentTarget.hasAttribute("title")) {
      const tooltip = Tooltip.getOrCreateInstance(currentTarget);
      tooltip.hide();
    }
  }
}
