import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "buttonText", "label"];

  static values = {
    buttonText: String,
    buttonUrl: String,
  }

  defaultLabelText;

  labelTargetConnected() {
    this.defaultLabelText = this.labelTarget.textContent;
  }

  checkButtonText({ target: buttonTextField}) {
    this.buttonTextValue = buttonTextField.value;
  }

  checkLinkbackUrl({ target: buttonUrlField}) {
    this.buttonUrlValue = buttonUrlField.value;
  }

  buttonTextValueChanged() {
    this.buttonTextTarget.textContent = this.buttonTextValue;

    if (!this.defaultLabelText) return;

    if (this.buttonTextValue === "") {
      this.labelTarget.textContent = "Button must have text";
    } else {
      this.labelTarget.textContent = this.defaultLabelText;
    }
  }

  buttonUrlValueChanged() {
    this.buttonTarget.href = this.buttonUrlValue;

    if (!this.defaultLabelText) return;

    if (this.buttonUrlValue === "") {
      this.labelTarget.textContent = "Button must have a URL";
    } else {
      this.labelTarget.textContent = this.defaultLabelText;
    }
  }
}
