import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["player"];

  videoEnabled = false;

  // eslint-disable-next-line class-methods-use-this
  playerTargetConnected() {
    // Observe for when the video element leaves the viewport and auto-pause the video
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Check if the target element is currently not fully visible in the viewport
          if (entry.intersectionRatio !== 1) {
            this.playerTarget.pause();
          }
        });
      },
      {
        // Set the threshold to 0 to trigger the observer whenever the element leaves the viewport
        threshold: 0,
      }
    );

    // Start observing the video player element
    observer.observe(this.playerTarget);
  }

  playAndEnableControlsOnce() {
    // eslint-disable-next-line no-unused-expressions
    if (this.videoEnabled) return;

    this.playerTarget.play();
    this.playerTarget.setAttribute("controls", "true");
    this.videoEnabled = true;
  }
}
