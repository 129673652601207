import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["heightField", "widthField", "ppiField", "ppiPreview", "icon", "failureCard", "successCard"];

  static values = {
    pxHeight: Number,
    pxWidth: Number,
    ppi: Number,
    keepAspectRatio: { type: Boolean, default: true },
  };

  get aspectRatio() {
    return this.pxWidthValue / this.pxHeightValue;
  }

  updateHeight() {
    if (this.keepAspectRatioValue) {
      this.#calculateOtherField({ height: this.heightFieldTarget.value });
    }
    this.#calculatePpi();
  }

  updateWidth() {
    if (this.keepAspectRatioValue) {
      this.#calculateOtherField({ width: this.widthFieldTarget.value });
    }
    this.#calculatePpi();
  }

  updatePpi() {
    this.ppiValue = this.ppiFieldTarget.value
    this.widthFieldTarget.value = (this.pxWidthValue / this.ppiFieldTarget.value).toFixed(1);
    this.heightFieldTarget.value = (this.pxHeightValue / this.ppiFieldTarget.value).toFixed(1);
  }

  toggleKeepAspectRatio() {
    this.keepAspectRatioValue = !this.keepAspectRatioValue;
    this.#switchIcon();
  }

  ppiValueChanged() {
    this.ppiFieldTarget.value = this.ppiValue;
    Array.from(this.ppiPreviewTargets).forEach((ppiPreview) => {
      ppiPreview.innerHTML = this.ppiValue;
    });
    this.#switchFeedbackCard();
  }

  #calculatePpi() {
    this.ppiValue = Math.round(
        Math.sqrt(this.pxWidthValue ** 2 + this.pxHeightValue ** 2) /
        Math.sqrt(this.widthFieldTarget.value ** 2 + this.heightFieldTarget.value ** 2)
    );
  }

  #calculateOtherField({ height, width }) {
    if (height !== undefined && width !== undefined) {
      return;
    }

    if (height !== undefined) {
      this.widthFieldTarget.value = (height * this.aspectRatio).toFixed(1);
      return;
    }

    if (width !== undefined) {
      this.heightFieldTarget.value = (width / this.aspectRatio).toFixed(1);
    }
  }

  #switchFeedbackCard() {
    if (this.ppiValue === 0 || this.ppiValue === Infinity) {
      this.successCardTarget.classList.add("d-none");
      this.failureCardTarget.classList.add("d-none");
    } else if (this.ppiValue >= 150 ) {
      this.failureCardTarget.classList.add("d-none");
      this.successCardTarget.classList.remove("d-none");
    } else {
      this.successCardTarget.classList.add("d-none");
      this.failureCardTarget.classList.remove("d-none");
    }
  }

  #switchIcon() {
    const icon = this.iconTarget;
    if (icon.classList.contains("bi-lock")) {
      icon.classList.remove("bi-lock");
      icon.classList.add("bi-unlock");
    } else {
      icon.classList.remove("bi-unlock");
      icon.classList.add("bi-lock");
    }
  }
}
