import { DirectUpload } from "@rails/activestorage";

class StatusCard {
  element; labelElement; dismissButtonElement;
  hiddenElementClass = "d-none";

  create() {
    // parent element
    this.element = document.createElement("div");
    this.element.classList.add("col");
    this.element.classList.add("segment-photo-upload-tracker");

    // parent element > trackerCard
    const trackerCard = document.createElement("div");
    trackerCard.classList.add("card");
    trackerCard.classList.add("p-3");
    trackerCard.classList.add("vstack");
    this.element.appendChild(trackerCard);

    // parent element > trackerCard > statusLabel
    this.labelElement = document.createElement("p");
    this.labelElement.classList.add("status-label");
    this.labelElement.classList.add("text-center");
    trackerCard.appendChild(this.labelElement);

    // parent element > trackerCard > closeButton
    this.dismissButtonElement = document.createElement("div");
    this.dismissButtonElement.classList.add("mt-3");
    this.dismissButtonElement.classList.add("mx-auto");
    this.dismissButtonElement.classList.add("btn");
    this.dismissButtonElement.classList.add("btn-sm");
    this.dismissButtonElement.classList.add("btn-primary");
    this.dismissButtonElement.classList.add(this.hiddenElementClass);
    this.dismissButtonElement.innerText = "Remove";
    trackerCard.appendChild(this.dismissButtonElement);

    this.dismissButtonElement.addEventListener("click", () => {
      this.destroy();
    });

    return this.element;
  }

  setLabel(text) {
    this.labelElement.textContent = text;
  }

  showDismissButton() {
    this.dismissButtonElement.classList.remove(this.hiddenElementClass);
  }

  destroy() {
    this.element.remove();
  }
}

const States = {
  Pending: 'pending',
  Processing: 'processing',
  Succeeded: 'succeeded',
  Failed: 'failed'
};

class NewSegmentPhotoManager {
  state = States.Pending;
  statusCard;

  constructor(config = {}) {
    this.createSegmentPhotoUrl = config.createSegmentPhotoUrl;
    this.directUploadUrl = config.directUploadUrl;
    this.csrfToken = config.csrfToken;
    this.domFile = config.domFile;
    this.onSucceed = config.onSucceed;
    this.onFail = config.onFail;

    // ActiveStorage Direct Uploads API
    this.directUploader = new DirectUpload(
      config.domFile,
      config.directUploadUrl,
      this
    );
  }

  renderStatusCard(statusCardsContainerElement) {
    this.statusCard = new StatusCard();
    this.statusCard.create();
    statusCardsContainerElement.prepend(this.statusCard.element);
  }

  processFile() {
    this.statusCard?.setLabel("Uploading..");
    this.state = States.Processing;

    this.directUploader.create((error, blob) => {
      if (error) {
        this.#fail("Upload Failed");
      } else {
        this.statusCard?.setLabel("Loading..");
        this.#createSegmentPhoto(blob.signed_id);
      }
    });
  }

  get processing() {
     return this.state === States.Processing;
  }

  #fail(failureDescription) {
    if (!this.processing) return;

    this.state = States.Failed;
    this.statusCard?.setLabel(failureDescription);
    this.statusCard?.showDismissButton();

    if (this.onFail) this.onFail();
  }

  #succeed() {
    if (!this.processing) return;

    this.state = States.Succeeded;
    this.statusCard?.destroy();

    if (this.onSucceed) this.onSucceed();
  }

  #createSegmentPhoto(signedImageId) {
    // Create a formData object to send to the server
    const formData = new FormData();
    formData.append("_method", "post");
    formData.append("authenticity_token", this.csrfToken);
    formData.append("segment_photo[source_file]", signedImageId);

    // Create a request object
    const request = new XMLHttpRequest();
    request.timeout = 300000;
    request.open("POST", this.createSegmentPhotoUrl);

    // Catch errors
    request.onerror = () => { this.#fail("Record creation failed unexpectedly") };
    request.ontimeout = () => { this.#fail("Record creation timed out") };

    // Handle success state
    request.onload = () => {
      if (request.status >= 200 && request.status < 300) {
        this.#succeed();
      } else {
        const error = JSON.parse(request.response).message || "There was a problem processing your image."
        this.#fail(error)
      }
    };

    // Submit psuedo form
    request.send(formData);
  }

  #directUploadDidProgress(percentComplete) {
    this.statusCard?.setLabel(`${percentComplete}%`);
  }

  // === Delegate Methods for ActiveStorage Direct Uploads API ===

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => {
      const percentComplete = ((event.loaded / event.total) * 100).toFixed(1);
      this.#directUploadDidProgress(percentComplete);
    });
  }
}

export default NewSegmentPhotoManager;
