import { Controller } from "@hotwired/stimulus";
import hljs from "highlight.js/lib/common";

export default class extends Controller {
  static target = ["codeBlock"];

  static values = {
    language: { type: String, default: "auto" }
  };

  codeBlockTargetConnected(targetElement) {
    if (this.languageValue !== "auto") {
      const languageClass = `language-${this.languageValue}`;
      targetElement.classList.add(languageClass);
    }

    hljs.highlightElement(targetElement);
  }
}
