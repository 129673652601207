import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "selectField",
    "selectionLabel",
    "searchBox",
    "searchField",
    "searchResultList",
    "searchResultTemplate",
    "searchResult"
  ];

  static values = {
    placeholder: String,
    autoSubmit: Boolean,
    selectFieldClass: String
  };

  connect() {
    this.#setupSelectField();
    this.#setupSelectionLabel();
    this.#setupSearchBox();
  }

  #setupSelectField() {
    const selectFieldSelector = `.${this.selectFieldClassValue}`;
    const selectField = this.element.querySelector(selectFieldSelector);

    selectField.classList.add("d-none");
    selectField.dataset.searchableSelectTarget = "selectField";
  }

  #setupSelectionLabel() {
    const selectedSearchResultId = this.selectFieldTarget.selectedIndex;
    const selectedSearchResult = this.selectFieldTarget.options[selectedSearchResultId];
    const { value: selectionValue, text: selectionText } = selectedSearchResult || {};

    const labelText = selectionValue ? selectionText : this.placeholderValue;
    this.selectionLabelTarget.innerText = labelText;
  }

  #setupSearchBox() {
    // Define searchResult element from disposable searchResultTemplateTarget
    const searchResultTemplate = this.searchResultTemplateTarget.cloneNode();
    const templateTargets = searchResultTemplate.dataset.searchableSelectTarget;
    const resultTargets = templateTargets.replace("searchResultTemplate", "searchResult");
    searchResultTemplate.dataset.searchableSelectTarget = resultTargets;
    this.searchResultTemplateTarget.remove();

    // Generate searchResult rows
    for (let { text, value, disabled } of this.selectFieldTarget.options) {
      const searchResult = searchResultTemplate.cloneNode();
      searchResult.text = text;
      searchResult.dataset.value = value;
      if (disabled) searchResult.classList.add("disabled");
      this.searchResultListTarget.appendChild(searchResult);
    }

    // Close searchBox if unrelated element clicked
    document.addEventListener("click", (event) => {
      if (this.searchBoxIsClosed) return;
      if (this.element.contains(event.target)) return;

      event.preventDefault();
      this.closeSearchBox();
    });

    // Close searchBox with "Escape" key
    document.addEventListener("keydown", (event) => {
      if (event.key !== "Escape" || this.searchBoxIsClosed) return;

      event.preventDefault();
      this.closeSearchBox();
    });

    // Select first searchResult with "Enter" key
    this.searchFieldTarget.addEventListener("keydown", (event) => {
      if (event.key !== "Enter") return;

      event.preventDefault();
      this.selectResult(this.filteredSearchResults[0]);
    });
  }

  toggleSearchBox() {
    this.searchBoxTarget.classList.toggle("d-none");
    this.searchFieldTarget.focus();
  }

  closeSearchBox() {
    if (this.searchBoxTarget.classList.contains("d-none")) return;

    this.searchBoxTarget.classList.add("d-none");
  }

  filterSearchResults() {
    const queryString = this.searchFieldTarget.value.toUpperCase();

    this.searchResultTargets.forEach((searchResult) => {
      const resultString = searchResult.text.toUpperCase();

      if (resultString.includes(queryString)) {
        searchResult.classList.remove("d-none");
      } else {
        searchResult.classList.add("d-none");
      }
    });
  }

  selectResult(eventOrSearchResult) {
    if (!eventOrSearchResult) return;

    const searchResult = eventOrSearchResult.target || eventOrSearchResult;
    const { value: selectedValue } = searchResult.dataset;
    const labelText = selectedValue ? searchResult.text : this.placeholderValue;

    this.selectFieldTarget.value = selectedValue;
    this.selectionLabelTarget.innerText = labelText;

    this.toggleSearchBox();

    if (this.autoSubmitValue) Rails.fire(this.selectFieldTarget.form, "submit");
  }

  get filteredSearchResults() {
    return this.searchResultTargets.filter(searchResult => !searchResult.classList.contains("d-none"));
  }

  get searchBoxIsClosed() {
    return this.searchBoxTarget.classList.contains("d-none");
  }
}
