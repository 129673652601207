import { Controller } from "@hotwired/stimulus";
import { Alert } from "bootstrap";

export default class extends Controller {
  timeout = null;

  connect() {
    this.dismissLater(8000);
  }

  dismiss() {
    const alert = Alert.getOrCreateInstance(this.element);
    alert.close();
  }

  cancelDismissal() {
    clearTimeout(this.timeout);
  }

  dismissLater(delay) {
    const dismissalDelay = typeof delay === 'number' ? delay : 750;

    if (this.timeout) this.cancelDismissal();

    this.timeout = setTimeout(() => {
      this.dismiss();
    }, dismissalDelay);
  }
}
