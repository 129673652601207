import Rails from "@rails/ujs";

/* eslint-disable camelcase */

import {
  setPlugins,
  openEditor,
  locale_en_gb,
  plugin_finetune,
  plugin_finetune_defaults,
  createDefaultImageReader,
  createDefaultImageWriter,
  plugin_finetune_locale_en_gb,
} from "plugins/pintura";

const initializeEditor = (referenceImageUrl) => {
  // Disable tools that can disrupt color transfer process
  let { finetuneOptions } = plugin_finetune_defaults;
  finetuneOptions = finetuneOptions.filter((option) => option[0] !== "clarity");
  finetuneOptions = finetuneOptions.filter((option) => option[0] !== "vignette");
  plugin_finetune_defaults.finetuneOptions = finetuneOptions;

  // Merge all the locale objects
  const locale = { ...locale_en_gb, ...plugin_finetune_locale_en_gb };

  // Make a writer that updates and submits the form
  const imageWriter = createDefaultImageWriter({
    copyImageHead: true, // Retain image EXIF data
    orientImage: true, // Fix image orientation
    mimeType: "image/png", // Convert input image to png
    quality: 1, // Keep quality at 100%

    store: (state) =>
      new Promise((resolve, reject) => {
        const referenceImageField = document.getElementById("reference-image-field");
        const referenceImageForm = referenceImageField.closest("form");
        const referenceImageFile = state.dest;
        const tempFiles = new DataTransfer();

        referenceImageForm.addEventListener("ajax:error", (event, xhr, status, error) => {
          reject(error);
        });

        tempFiles.items.add(referenceImageFile);
        referenceImageField.files = tempFiles.files;
        Rails.fire(referenceImageForm, "submit");
      }),
  });

  setPlugins(plugin_finetune);

  const editor = openEditor({
    src: referenceImageUrl,
    imageReader: createDefaultImageReader(),
    ...plugin_finetune_defaults,
    imageWriter,
    locale,
  });

  editor.on("loaderror", ({error}) => {
    editor.destroy();
    alert(`Failed to load image editor: ${error.message}`);
  });

  const cleanUpEditorListener = async () => {
    // This may be flaky due to timing
    // We really need: https://github.com/hotwired/turbo/pull/290
    await editor.close();
    await editor.destroy();

    // Manually clear the lock just in case cleanup fails
    document.documentElement.classList.remove("PinturaDocumentLock");
  };

  // Clean up editor before moving on (since we replace page w/ turbo)
  document.addEventListener("turbo:before-render", cleanUpEditorListener, { once: true });
};

/* eslint-enable camelcase */

document.addEventListener("turbo:load", () => {
  const editReferenceImageArea = document.getElementById("edit-reference-image-area");
  if (!editReferenceImageArea) return;

  const editButton = editReferenceImageArea.querySelector("#open-editor-button");
  const compositeImageUrl = editButton.dataset.imageUrl;
  const autoLaunchEnabled = editButton.dataset.autoLaunch === 'true';

  // Auto-open editor unless already unedited
  if (autoLaunchEnabled) {
    initializeEditor(compositeImageUrl);
  }

  // Open editor when "Edit" button clicked
  editButton.addEventListener("click", () => {
    initializeEditor(compositeImageUrl);
  });
});
