import Popover from "bootstrap/js/src/popover";
import handleBubbledEvent from "./utils/bubbled_event_handling";

export const enablePopovers = (anchorSelector) => {
    const applicationContainerSelector = "#application-container";
    const popoverTriggerSelector = anchorSelector || "[data-bs-toggle='popover']";

    const showPopover = (event) => {
        const { target: popoverTrigger } = event;
        const sanitizeHtml = popoverTrigger.dataset.bsSanitize !== "false";

        const popover = Popover.getOrCreateInstance(popoverTrigger, {
            sanitize: sanitizeHtml
        });

        document.querySelectorAll(popoverTriggerSelector).forEach(el => {
            const instance = bootstrap.Popover.getInstance(el);
            if (instance && el !== popoverTrigger) {
                instance.hide();
            }
        });

        popover.toggle();
    };

    handleBubbledEvent("mouseover", applicationContainerSelector, popoverTriggerSelector, showPopover);

    handleBubbledEvent("click", applicationContainerSelector, popoverTriggerSelector, showPopover);
    handleBubbledEvent("touchstart", applicationContainerSelector, popoverTriggerSelector, showPopover);
};
