import { loadStripe } from "@stripe/stripe-js/pure";

export default class CheckoutForm {
  // eslint-disable-next-line lines-between-class-members, prettier/prettier
  stripeJs; stripeElements; cardCvcField; cardNumberField; cardExpiryField;

  supportedStripeElementFieldTypes = ["cardCvc", "cardNumber", "cardExpiry"];

  constructor(config) {
    this.stripePublicKey = config.key;
    this.stripeInvoiceData = config.stripeInvoiceData;
    this.formElement = config.formElement;
    this.submitElement = config.submitElement;
  }

  async loadStripeElements() {
    this.stripeJs = await loadStripe(this.stripePublicKey);
    this.stripeElements = this.stripeJs.elements();

    if (this.total !== 0) this.disableSubmit();

    return this;
  }

  async payWithCard() {
    return this.stripeJs.confirmCardPayment(this.clientSecret, {
      payment_method: {
        card: this.getStripeElementField("cardNumber"),
      },
      setup_future_usage: "off_session",
    });
  }

  mountCvcField(selector) {
    this.cardCvcField = document.querySelector(selector);
    this.cardCvcField.dataset.type = "cardCvc";

    this.#mountCardField("cardCvc", selector);
  }

  mountNumberField(selector) {
    this.cardNumberField = document.querySelector(selector);
    this.cardNumberField.dataset.type = "cardNumber";

    this.#mountCardField("cardNumber", selector);
  }

  mountExpiryField(selector) {
    this.cardExpiryField = document.querySelector(selector);
    this.cardExpiryField.dataset.type = "cardExpiry";

    this.#mountCardField("cardExpiry", selector);
  }

  disableSubmit(label) {
    if (label) this.submitElement.textContent = label;

    this.submitElement.disabled = true;
    this.submitElement.classList.add("disabled");
  }

  enableSubmit(label) {
    if (label) this.submitElement.textContent = label;

    this.submitElement.disabled = false;
    this.submitElement.classList.remove("disabled");
  }

  getStripeElementField(fieldType) {
    if (!this.supportedStripeElementFieldTypes.includes(fieldType)) return false;

    return this.stripeElements.getElement(fieldType);
  }

  get clientSecret() {
    return this.stripeInvoiceData.payment_intent.client_secret;
  }

  get total() {
    let { total } = this.stripeInvoiceData;

    (total /= 100.0).toFixed(2);

    return total;
  }

  get couponName() {
    const { discounts } = this.stripeInvoiceData;

    if (discounts.length === 0) return null;

    return discounts[0].coupon.name;
  }

  get cardFields() {
    const fields = [this.cardCvcField, this.cardNumberField, this.cardExpiryField];

    return fields.filter((field) => field);
  }

  #mountCardField = (fieldType, selector) => {
    const stripeElementField = this.stripeElements.create(fieldType, {
      style: this.#cardFieldStyle,
      classes: this.#cardClasses,
    });

    stripeElementField.mount(selector);

    stripeElementField.on("change", this.#validateCardField);
  };

  #validateCardField = (event) => {
    const { complete, elementType } = event;

    let isComplete = false;

    if (complete) {
      // Ignore current field since it hasn't been updated yet
      const otherFields = this.cardFields.filter((field) => field.dataset.type !== elementType);
      isComplete = otherFields.every((field) => field.classList.contains("is-complete"));
    }

    if (isComplete) {
      this.enableSubmit();
    } else {
      this.disableSubmit();
    }
  };

  #cardClasses = {
    invalid: "is-invalid",
    complete: "is-complete",
    empty: "is-empty",
  };

  #cardFieldStyle = {
    base: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial',
      fontSize: "17.6px",
      fontWeight: "400",
      color: "#343a40",

      "::placeholder": {
        color: "#868e96",
      },
    },

    invalid: {
      color: "#d9534f",
      ":focus": {
        color: "#343a40",
      },
      "::placeholder": {
        color: "#d9534f",
      },
    },
  };
}
