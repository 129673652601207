import Trix from "trix";

const removeH1Button = () => {
  const ogHtml = Trix.config.toolbar.getDefaultHTML();
  const parser = new DOMParser();
  const doc = parser.parseFromString(ogHtml, "text/html");

  const headingButton = doc.querySelector('[data-trix-attribute="heading1"]');
  headingButton.remove();
  const updatedHtml = doc.body.innerHTML;

  Trix.config.toolbar.getDefaultHTML = () => {
    return updatedHtml;
  };
};

const prependToBlockToolbar = (buttonHtml) => {
  const ogHtml = Trix.config.toolbar.getDefaultHTML();
  const parser = new DOMParser();
  const doc = parser.parseFromString(ogHtml, 'text/html');
  const textToolsGroup = doc.querySelector('.trix-button-group--block-tools');

  if (textToolsGroup) textToolsGroup.insertAdjacentHTML('afterbegin', buttonHtml);
  const updatedHtml = doc.body.innerHTML;

  Trix.config.toolbar.getDefaultHTML = () => {
    return updatedHtml;
  };
};

const appendToBlockToolbar = (buttonHtml) => {
  const ogHtml = Trix.config.toolbar.getDefaultHTML();
  const parser = new DOMParser();
  const doc = parser.parseFromString(ogHtml, "text/html");
  const textToolsGroup = doc.querySelector(".trix-button-group--block-tools");

  if (textToolsGroup) textToolsGroup.insertAdjacentHTML("beforeend", buttonHtml);
  const updatedHtml = doc.body.innerHTML;

  Trix.config.toolbar.getDefaultHTML = () => {
    return updatedHtml;
  };
};

const appendToTextToolbar = (buttonHtml) => {
  const ogHtml = Trix.config.toolbar.getDefaultHTML();
  const parser = new DOMParser();
  const doc = parser.parseFromString(ogHtml, "text/html");
  const textToolsGroup = doc.querySelector('.trix-button-group--text-tools');

  if (textToolsGroup) textToolsGroup.insertAdjacentHTML("beforeend", buttonHtml);
  const updatedHtml = doc.body.innerHTML;

  Trix.config.toolbar.getDefaultHTML = () => {
    return updatedHtml;
  };
};

const defineH1Button = () => {
  Trix.config.blockAttributes.heading1 = {
    tagName: "h1",
    terminal: true,
    breakOnReturn: true,
    group: false,
  };

  const buttonHtml = `
    <button type="button" class="trix-button trix-button--custom-icon fs-5 lh-1" data-trix-attribute="heading1" title="Heading 1">
      <i class="bi bi-type-h1"></i>
    </button>
  `;

  prependToBlockToolbar(buttonHtml);
};

const defineH2Button = () => {
  Trix.config.blockAttributes.heading2 = {
    tagName: "h2",
    terminal: true,
    breakOnReturn: true,
    group: false,
  };

  const buttonHtml = `
    <button type="button" class="trix-button trix-button--custom-icon fs-5 lh-1" data-trix-attribute="heading2" title="Heading 2">
      <i class="bi bi-type-h2"></i>
    </button>
  `;

  prependToBlockToolbar(buttonHtml);
};

const defineH3Button = () => {
  Trix.config.blockAttributes.heading3 = {
    tagName: "h3",
    terminal: true,
    breakOnReturn: true,
    group: false,
  };

  const buttonHtml = `
    <button type="button" class="trix-button trix-button--custom-icon fs-5 lh-1" data-trix-attribute="heading3" title="Heading 3">
      <i class="bi bi-type-h3"></i>
    </button>
  `;

  prependToBlockToolbar(buttonHtml);
};

const defineH4Button = () => {
  Trix.config.blockAttributes.heading4 = {
    tagName: "h4",
    terminal: true,
    breakOnReturn: true,
    group: false,
  };

  const buttonHtml = `
    <button type="button" class="trix-button trix-button--custom-icon fs-5 lh-1" data-trix-attribute="heading4" title="Heading 4">
      <i class="bi bi-type-h4"></i>
    </button>
  `;

  prependToBlockToolbar(buttonHtml);
};

const defineTextForegroundButton = () => {
  Trix.config.textAttributes.foregroundColor = {
    styleProperty: "color",
    inheritable: true,
    parser(element) {
      return element.style.color.length > 0;
    },
  };

  const buttonHtml = `
    <input type="color" style="width:0; height:0; padding:0; margin-top:20px; visibility:hidden;"
    data-trix-target="foregroundColorPicker" data-action="trix#updateForegroundColor">

    <button type="button" class="trix-button trix-button--custom-icon fs-5 lh-1" data-trix-attribute="foregroundColor" 
    data-action="click->trix#openForegroundColorPicker" title="Font Color">
      <i class="bi bi-palette"></i>
    </button>
  `;

  appendToTextToolbar(buttonHtml);
};

const defineTextBackgroundButton = () => {
  Trix.config.textAttributes.backgroundColor = {
    styleProperty: "backgroundColor",
    inheritable: true,
    parser(element) {
      return element.style.backgroundColor.length > 0;
    },
  };

  const buttonHtml = `
    <input type="color" style="width:0; height:0; padding:0; margin-top:20px; visibility:hidden;"
    data-trix-target="backgroundColorPicker" data-action="trix#updateBackgroundColor">

    <button type="button" class="trix-button trix-button--custom-icon fs-5 lh-1" data-trix-attribute="backgroundColor" 
    data-action="click->trix#openBackgroundColorPicker" title="Highlight">
      <i class="bi bi-highlighter"></i>
    </button>
  `;

  appendToTextToolbar(buttonHtml);
};

const defineTextAlignCenterButton = () => {
  Trix.config.blockAttributes.textAlignCenter = {
    tagName: "centered-div",
    nestable: false,
    exclusive: true,
  };

  const buttonHtml = `
    <button type="button" class="trix-button trix-button--custom-icon fs-5 lh-1" data-trix-attribute="textAlignCenter" title="Align Center">
      <i class="bi bi-text-center"></i>
    </button>
  `;

  appendToBlockToolbar(buttonHtml);
};

export const customizeTrixOnInit = () => {
  document.addEventListener("trix-before-initialize", () => {
    removeH1Button();
    defineH4Button();
    defineH3Button();
    defineH2Button();
    defineH1Button();
    defineTextAlignCenterButton();
    defineTextForegroundButton();
    defineTextBackgroundButton();
  }, { once: true });
};
