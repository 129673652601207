import { Controller } from "@hotwired/stimulus";

// TODO: Refactor reference image views to use this

export default class extends Controller {
  static targets = ["container"];

  static values = {
    visible: Boolean,
  };

  overlay;

  initialize() {
    this.#createOverlay();
  }

  visibleValueChanged() {
    if (this.visibleValue) {
      this.#appendSpinner();
    } else {
      this.#removeSpinner();
    }
  }

  show() {
    this.visibleValue = true;
  }

  hide() {
    this.visibleValue = false;
  }

  #createOverlay() {
    const spinnerOverlay = document.createElement("div");
    const spinnerContainer = document.createElement("div");
    const spinner = document.createElement("div");

    spinnerOverlay.id = "spinner-overlay";
    spinnerContainer.id = "spinner-container";
    spinner.id = "spinner";

    spinnerOverlay.appendChild(spinnerContainer);
    spinnerContainer.appendChild(spinner);

    this.overlay = spinnerOverlay;
  }

  #appendSpinner() {
    this.containerTarget.appendChild(this.overlay);
  }

  #removeSpinner() {
    this.containerTarget.removeChild(this.overlay);
  }
}
