import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["rateField", "potentialValueLabel", "rateStaticField", "rateStaticLabel"];

  static values = {
    rate: Number,
    rateStatic: Boolean,
    unlockPrice: Number
  };

  connect() {
    this.updateRateStatic();
    this.updateRate();
  }

  updateRate() {
    this.rateValue = this.rateFieldTarget.value;

    this.#updatePotentialValueLabel();
  }

  updateRateStatic() {
    this.rateStaticValue = this.rateStaticFieldTarget.checked;

    this.#updateRateStaticLabel();
    this.#updatePotentialValueLabel();
  }

  #updatePotentialValueLabel() {
    this.potentialValueLabelTarget.textContent = `$${this.potentialValue}`;

    if (this.rateStaticValue) {
      this.potentialValueLabelTarget.classList.add("d-none");
    } else {
      this.potentialValueLabelTarget.classList.remove("d-none");
    }
  }

  #updateRateStaticLabel() {
    const rateSigil = this.rateStaticValue ? "$" : "%";
    this.rateStaticLabelTarget.textContent = rateSigil;
  }

  get potentialValue() {
    if (this.rateStaticValue) {
      return this.rateValue;
    } else {
      return (this.unlockPriceValue * this.rateValue).toFixed(2);
    }
  }
}
