// Note: Expects top-level `#application-container` element for tracking
// bubbled mouseover events.

import Tooltip from "bootstrap/js/src/tooltip";
import handleBubbledEvent from "./utils/bubbled_event_handling";

export const enableTooltips = (anchorSelector) => {
  const applicationContainerSelector = "#application-container";
  const tooltipTriggerSelector = anchorSelector || "[data-bs-toggle='tooltip']";

  handleBubbledEvent("mouseover", applicationContainerSelector, tooltipTriggerSelector, (event) => {
    const { target: tooltipTrigger } = event;
    const tooltip = Tooltip.getOrCreateInstance(tooltipTrigger);
    tooltip.show();
  });
};

export const destroyTooltips = () => {
  document.querySelectorAll(".tooltip").forEach((tooltipElement) => {
    const tooltip = Tooltip.getOrCreateInstance(tooltipElement);
    tooltip.dispose();
  });
};
