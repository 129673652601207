import moment from "moment";

// Read  & writes as ISO 8601
// For use with: app/views/shared/_duration_field
// https://en.wikipedia.org/wiki/ISO_8601#Durations

class DurationField {
  constructor(fieldGroup) {
    this.fieldGroup = fieldGroup;
    this.hostField = document.getElementById(fieldGroup.dataset.parentId);

    this.partField = fieldGroup.querySelector(`select[name="duration_part"]`);
    this.valueField = fieldGroup.querySelector(`input[name="duration_value"]`);
  }

  enable() {
    this.loadDurationFields();

    this.fieldGroup.addEventListener("change", () => {
      this.updateHostField();
    });
  }

  loadDurationFields() {
    const isoDuration = this.hostField.value;
    if (!isoDuration) return;

    const currentDuration = moment.duration(isoDuration);
    let currentPart;
    let currentValue;

    // eslint-disable-next-line no-underscore-dangle
    const { years, months, days } = currentDuration._data;

    // Check which date parts duration is composed of
    const currentParts = [];
    if (years > 0) currentParts.push("years");
    if (months > 0) currentParts.push("months");
    if (days > 0) currentParts.push("days");

    // Convert to "days" if multiple parts present
    if (currentParts.length === 1) {
      [currentPart] = currentParts;
      currentValue = currentDuration.as(currentPart);
    } else {
      currentPart = "days";
      currentValue = currentDuration.asDays();
    }

    // Convert days to weeks if divisible by 7
    if (currentPart === "days" && currentValue % 7 === 0) {
      currentPart = "weeks";
      currentValue = currentDuration.asWeeks();
    }

    this.valueField.value = Math.round(currentValue);
    this.partField.value = currentPart;
  }

  updateHostField() {
    const targetPart = this.partField.value;
    const targetValue = this.valueField.value;

    if (targetValue) {
      const targetDuration = moment.duration(targetValue, targetPart);
      this.hostField.value = targetDuration.toISOString();
    } else {
      this.hostField.value = null;
    }
  }
}

const enableDurationFields = () => {
  const durationFieldInputGroups = document.querySelectorAll(".duration-field");

  durationFieldInputGroups.forEach((durationFieldInputGroup) => {
    const durationField = new DurationField(durationFieldInputGroup);
    durationField.enable();
  });
};

export default enableDurationFields;
