 

import {
    createDefaultImageReader,
    createDefaultImageWriter,
    locale_en_gb,
    openEditor,
    plugin_crop,
    plugin_crop_locale_en_gb,
    setPlugins,
} from "plugins/pintura";

export const initializeEditor = (sourceUrl, previousTransformations) => {
  const token = document.head.querySelector('meta[name="csrf-token"]').content;

  // Replace URL with viewable_composite path
  const stitchBaseURLRegex = /\/stitches\/(\d+)(\/.*)?$/;
  let uploadURL = document.URL.replace(stitchBaseURLRegex, '/stitches/$1/viewable_composite');

  const imageWriter = createDefaultImageWriter({
      canvasMemoryLimit: Infinity, // Remove canvas size limits to avoid downscaling
      orientImage: true, // Fix image orientation
      copyImageHead: true, // Retain image EXIF data
      mimeType: "image/png", // Convert input image to png
      quality: 1, // Keep quality at 100%

      // Post (patch) images to '/stitches/:stitch_id/viewable_composite'
      store: (state, options, onProgress) =>
        new Promise( async (resolve, reject) => {
          const {imageState} = state;
          const {src} = state;

          const {width, height} = await new Promise((resolve, reject) => {
            const img = new Image();

            img.onload = function () {
                resolve({width: img.width, height: img.height});
            };
            img.onerror = function () {
                reject(new Error('Failed to load image'));
            };

            img.src = URL.createObjectURL(src);
          });

          // Create a formData object to send to the server
          const formData = new FormData();
          formData.append("_method", "patch");
          formData.append("authenticity_token", token);
          formData.append("composite_image[viewable_file_changes]", JSON.stringify({ ...imageState, width, height }));

          // Create a request object
          const request = new XMLHttpRequest();
          request.timeout = 90000;
          request.open("PATCH", uploadURL);

          // Show progress in interface
          request.upload.onprogress = onProgress;

          // Catch errors
          request.onerror = () => reject(new Error("Something went wrong with the your request"));
          request.ontimeout = () => reject(new Error("Your request timed out"));

          // Handle success state
          request.onload = () => {
            if (request.status >= 200 && request.status < 300) {
              // Store request in state so it can be accessed by other processes
               
              state.store = request;
              resolve(state);
            } else {
              reject(new Error("Something went wrong!"));
            }
          };

          // Start uploading the image
          request.send(formData);
        }),

      // Show all output props
      outputProps: ["src", "dest", "imageState", "store"],
    });

    setPlugins(plugin_crop);

    const locale = { ...locale_en_gb, ...plugin_crop_locale_en_gb };
    const editor = openEditor({
      src: sourceUrl,
      imageReader: createDefaultImageReader(),
      imageWriter,
      locale,
    });

    if (previousTransformations) {
      editor.on('load', () => {
        // Add image state to history stack to replicate stored changes
        editor.history.write(previousTransformations);
      });
    }
};
