import { Controller } from "stimulus";

export default class extends Controller {
  // eslint-disable-next-line class-methods-use-this
  copy({ params: { target } }) {
    const targetElement = document.getElementById(target);

    navigator.clipboard.writeText(targetElement.value);
  }
}
